// StoreMyPage / OrderListPage 등에서 쓰이는 StoreCard 컴포넌트. 상품의 간단한 정보 여러 페이지로의 링크를 포함.
import React, { FC, useMemo } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopOrderForStore } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import Modal from '@shared/components/Modal';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { formatDate } from '@shared/utils/formatDate';
import { formatPrice } from '@shared/utils/formatPrice';
import { deliveryPaymentStateToString } from '@shared/utils/stateUtils';
import { Link } from 'react-router-dom';

import { ShopEnquiryBox } from '../ShopEnquiryBox/ShopEnquiryBox';

interface IProps {
  shopOrder: ShopOrderForStore;
  frameClassName?: string;
}

const StoreCard: FC<IProps> = ({ shopOrder, frameClassName }: IProps) => {
  const { ChevronRight } = Icon();

  const shopEnquiryModalOpenId = `store-card-shop-enquiry-modal-open-${shopOrder._id}`;
  const setModalOpen = useSetRecoilState(simpleOpenAtomFamily(shopEnquiryModalOpenId));

  const shopEnquryBoxModalRender = useMemo(() => {
    return (
      <Modal openId={shopEnquiryModalOpenId}>
        <ShopEnquiryBox shop={shopOrder.shop} />
      </Modal>
    );
  }, [shopEnquiryModalOpenId, shopOrder.shop]);

  return (
    <div
      className={`w-full rounded-2xl px-3 py-2 text-xs leading-5 shadow-smd theme-bg-1 ${frameClassName}`}
    >
      {/* Header */}
      <div className="flex items-center justify-between font-bold">
        <div className="flex items-center gap-x-2">
          {/* Tag */}
          <div className="rounded-full px-3 py-1 text-white theme-bg-14">
            {deliveryPaymentStateToString(
              shopOrder.paymentDetail.status,
              shopOrder.deliveryDetail.status,
            )}
          </div>
          {/* 배송 도착 예정 시각 */}
          <div className="theme-text-8">
            {formatDate(shopOrder.deliveryDetail.estimatedDeliveredAt, {
              dateSeparater: '.',
              contains: {
                year: false,
                day: true,
                hours: true,
                minutes: true,
              },
            })}{' '}
            도착
          </div>
        </div>
        {/* 주문상세보기 링크 */}
        <Link to={`/flower-store/order/${shopOrder._id}`}>
          <div className="flex items-center theme-text-main">
            주문상세보기
            <ChevronRight />
          </div>
        </Link>
      </div>
      {/* Content */}
      <div className="flex items-center justify-between pt-3">
        {/* 사진 / 상품정보 */}
        <div className="flex items-center space-x-2">
          {/* 상품 사진 */}
          <img
            src={fileBucketUrl(
              shopOrder.orderDetail.shopItem.shopItemInfo.image.url,
              'w400',
            )}
            alt="Flower Image"
            className="aspect-square w-20 object-cover object-center"
          />
          <div className="flex flex-col justify-around space-y-1 text-xs leading-5">
            {/* 상품명 */}
            <h5 className="font-medium">{shopOrder.orderDetail.shopItemEmbed.name}</h5>
            {/* 상품 가격 */}
            {/* TODO: priceRetail or priceRetailShow? */}
            <span>{formatPrice(shopOrder.orderDetail.shopItemEmbed.priceRetail)}원</span>
            {/* 주문 수량 */}
            <span>수량: {shopOrder.orderDetail.quantity}개</span>
          </div>
        </div>
        {/* Buttons */}
        {/* TODO: delivery payment status에 따라 버튼의 구성을 다르게 해야함. */}
        {/* TODO: 버튼 기능 추가 */}
        <div className="flex flex-col space-y-1">
          <Button
            className="px-5 py-1 text-xs font-normal leading-5 shadow-none theme-text-8 theme-border-1"
            to={`/flower-store/order/${shopOrder._id}/delivery`}
          >
            배송조회(사진)
          </Button>
          <Button className="px-5 py-1 text-xs font-normal leading-5 shadow-none theme-text-8 theme-border-1">
            구매확정
          </Button>
          <Button
            onClick={() => {
              setModalOpen(true);
            }}
            className="px-5 py-1 text-xs font-normal leading-5 shadow-none theme-text-8 theme-border-1"
          >
            판매자문의
          </Button>
        </div>
      </div>
      {shopEnquryBoxModalRender}
    </div>
  );
};

export default React.memo(StoreCard);
