//* 배송조회페이지
import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { shopOrderForStoreStateSelectorFamily } from '@shared/api/shopOrder/shopOrder.selector';
import ClockLoader from '@shared/components/ClockLoader';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { Image } from 'antd';
import { useParams } from 'react-router-dom';

import { OrderDeliveryStateCard } from '../containers/OrderDelivery/OrderDeliveryStateCard';
import StoreCard from '../containers/StoreCard/StoreCard';
import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

const OrderDeliveryPage: FC = function OrderDeliveryPage() {
  const { orderId } = useParams();

  useStoreTitleHook('배송 조회', ' ', undefined);

  const ShopOrderForStoreState = useRecoilValue(
    shopOrderForStoreStateSelectorFamily(orderId ?? 'undefined'),
  );
  const shopOrder = ShopOrderForStoreState.data;
  return (
    <div className="space-y-3 px-4 py-3">
      {ShopOrderForStoreState.status === 'success' && shopOrder ? (
        <>
          {/* 배송상황 표시 */}
          <div>
            <OrderDeliveryStateCard shopOrder={shopOrder} />
          </div>
          {/* StoreCard (not rounded) */}
          <StoreCard shopOrder={shopOrder} frameClassName="pb-6" />
          {/* 배송 상세 정보 */}
          <h3 className="pt-3 text-sm font-bold">배송 상세 정보</h3>
          <div className="grid grid-cols-2 rounded-md px-3 py-3 text-sm shadow-smd theme-bg-1">
            {/* Text */}
            <div className="space-y-2">
              {/* 수령인 */}
              <div className="space-y-2">
                <h4 className="font-bold theme-text-8">수령인</h4>
                {/* 이름 */}
                <p>
                  <span className="whitespace-pre-line break-keep font-bold theme-text-main">
                    {shopOrder.deliveryDetail.receiverName}
                  </span>{' '}
                </p>
                {/* 주소 */}
                <p className="whitespace-pre-line break-keep text-xs leading-5">
                  {shopOrder.deliveryDetail.receiverAddress}
                </p>
              </div>
              {/* Vertical Line */}
              <div className="h-[1px] bg-gray-300"></div>
              {/* 문구 정보 */}
              <div className="space-y-2">
                <h4 className="font-bold theme-text-8">문구 정보</h4>
                <div>
                  <h6 className="text-[11px] leading-5 theme-text-8">보낸이</h6>
                  <p className="whitespace-pre-line break-keep">
                    {shopOrder.bugoDetail.senderPhrase}
                  </p>
                </div>
                <div>
                  <h6 className="text-[11px] leading-5 theme-text-8">조의문구</h6>
                  <p className="whitespace-pre-line break-keep">
                    {shopOrder.bugoDetail.condolencePhrase}
                  </p>
                </div>
              </div>
            </div>
            {/* 배송 Image */}
            <div className="flex flex-col items-center justify-center px-3 py-2">
              {shopOrder.deliveryDetail.deliveredImage?.url ? (
                <>
                  <Image
                    src={fileBucketUrl(
                      shopOrder.deliveryDetail.deliveredImage.url,
                      'w400',
                    )}
                    preview={{
                      src: fileBucketUrl(
                        shopOrder.deliveryDetail.deliveredImage.url,
                        'raw',
                      ),
                    }}
                    alt="Flower Image"
                    className="max-w-full object-cover object-center"
                  />
                  <p className="whitespace-pre-line break-keep px-2 py-1 text-center text-[11px] leading-4 text-gray-600">
                    클릭하여 확대 및 회전할 수 있습니다.
                  </p>
                </>
              ) : (
                <div className="center-box ml-4 flex-1 self-stretch p-2 shadow-smd">
                  <p className="whitespace-pre-line break-keep text-center text-xs theme-text-8">
                    배송완료사진 업로드예정
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="center-box h-screen-15">
          <ClockLoader />
        </div>
      )}
    </div>
  );
};

export default OrderDeliveryPage;
