import React, { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import DateFilterSearcher from '@service/bugo/components/DateFilterSearcher';
import { BugoStatRangeQueryEnum } from '@service/bugo/page/AgencyAdminBugoStatMobilePage';
import { Fevent } from '@shared/api/fevent/fevent.interface';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { ShopOrder } from '@shared/api/shopOrder/shopOrder.interface';
import { WorkerUser } from '@shared/api/user/user.interface';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { TextField } from '@shared/components/TextField';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { getDefaultDateFrom } from '@shared/utils/dateUtils';
import { filterPaidShopOrder } from '@shared/utils/filterPaidShopOrder';
import { mainBgColorGenerator } from '@shared/utils/mainColorGenerator';
import { Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import WorkerDetailModalOpener from '../WorkerDetailModalOpener/WorkerDetailModalOpener';

const columns: TableColumnsType<WorkerTableData> = [
  {
    width: '5%',
    title: <div className="break-keep">index</div>,
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    render: (index: number) => {
      return <p className="break-keep text-xs">{index + 1}</p>;
    },
  },
  {
    title: '소속',
    dataIndex: 'teamName',
    key: 'teamName',
    align: 'center',
    render: (teamName) => {
      return <p className="font-medium">{teamName}</p>;
    },
  },
  {
    title: '소속지역',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
    render: (region) => {
      return <p className="font-medium">{region}</p>;
    },
  },
  {
    title: '지도사',
    dataIndex: 'user',
    key: 'user',
    align: 'center',
    render: (user) => {
      return (
        <WorkerDetailModalOpener
          workerName={user.info.name}
          workerId={user._id}
          fontSize={14}
        />
      );
    },
  },
  {
    title: '부고등록',
    dataIndex: 'feventListLen',
    key: 'feventListLen',
    align: 'center',
    render: (value: number) => {
      return <p className="text-gray-600">{value.toLocaleString()}건</p>;
    },
    sorter: {
      compare: (a, b) => a.feventListLen - b.feventListLen,
      multiple: 1,
    },
  },
  {
    title: '화환판매수량',
    dataIndex: 'orderListLen',
    key: 'orderListLen',
    align: 'center',
    render: (value: number) => {
      return <p className="text-gray-600">{value.toLocaleString()}개</p>;
    },
    defaultSortOrder: 'descend',
    sorter: {
      compare: (a, b) => a.orderListLen - b.orderListLen,
      multiple: 2,
    },
  },
  {
    title: 'FPB',
    dataIndex: 'fpb',
    key: 'fpb',
    align: 'center',
    render: (fpb: number) => {
      return <p className="text-gray-600">{fpb.toFixed(2)}</p>;
    },
    sorter: {
      compare: (a, b) => a.fpb - b.fpb,
    },
  },
];

interface IProps {
  workerUserList: WorkerUser[];
  range: BugoStatRangeQueryEnum | null;
  teamTypesFilter: CheckBoxFilter;
}

type WorkerTableData = {
  id: string;
  index: number;
  user: WorkerUser;
  teamName: string;
  region: string;
  feventListLen: number;
  orderListLen: number;
  fpb: number;
};

const onDateRangeChange = (
  dates: [any, any] | null,
  setValue: React.Dispatch<React.SetStateAction<[any, any]>>,
) => {
  if (dates) {
    setValue(dates);
  } else {
    setValue([null, null]);
  }
};

const getOrderLenOfWorker = (worker: WorkerUser) => {
  let sum = 0;
  worker.bugoAgencyWorkerDetail?.fevents.forEach(
    (fevent) => (sum += filterPaidShopOrder(fevent.shopOrders).length),
  );

  return sum;
};

const AgencyAdminRankTable: FC<IProps> = ({
  workerUserList,
  range,
  teamTypesFilter,
}: IProps) => {
  const customTheme = useRecoilValue(customThemeAtom);
  const [searchValue, setSearchValue] = useState<string>('');

  const [dateRange, setDateRange] = useState<[any | null, any | null]>([null, null]);

  const [dateFilteredWorkerList, setDateFilteredWorkerList] = useState<WorkerUser[]>(
    workerUserList ?? [],
  );

  const [selectedTeamTypes, setSelectedTeamTypes] = useState(teamTypesFilter);

  const teamTypesFilteredWorkerList = useMemo(() => {
    return dateFilteredWorkerList.filter(
      (worker) => selectedTeamTypes[worker.bugoAgencyWorkerDetail?.teamType ?? ''],
    );
  }, [dateFilteredWorkerList, selectedTeamTypes]);

  const resultValue = useMemo(() => {
    const searcher = new FuzzySearch(teamTypesFilteredWorkerList, [
      'info.name',
      'bugoAgencyWorkerDetail.teamType',
      'bugoAgencyWorkerDetail.teamName',
    ]);
    return searcher.search(searchValue);
  }, [teamTypesFilteredWorkerList, searchValue]);

  const tableData: WorkerTableData[] = useMemo(() => {
    return _.map(
      resultValue.sort((a, b) => {
        return -getOrderLenOfWorker(a) + getOrderLenOfWorker(b);
      }) as WorkerUser[] | null,
      (worker, index): WorkerTableData => {
        return {
          id: worker._id,
          user: worker,
          index: index,
          teamName: getAgencyWorkerTeamName(worker),
          region: worker.bugoAgencyWorkerDetail?.region ?? '',
          feventListLen: worker.bugoAgencyWorkerDetail?.fevents.length ?? 0,
          orderListLen: getOrderLenOfWorker(worker),
          fpb:
            (worker.bugoAgencyWorkerDetail?.fevents.length ?? 0) === 0
              ? 0
              : getOrderLenOfWorker(worker) /
                (worker.bugoAgencyWorkerDetail?.fevents.length ?? 0),
        };
      },
    );
  }, [resultValue]);

  const csvTableData = tableData.map((item) => {
    return {
      소속: item.teamName,
      소속지역: item.region,
      지도사: item.user.info.name,
      부고등록건수: item.feventListLen,
      화환판매수량: item.orderListLen,
      FPB: item.fpb.toFixed(2),
    };
  });

  // default range 적용
  useEffect(() => {
    if (!range) return;

    const from = getDefaultDateFrom(range);
    const to = new Date();

    setDateRange([dayjs(from), dayjs(to)]);
  }, [range]);

  // dateRange 적용.
  useEffect(() => {
    if (!workerUserList || !dateRange[0] || !dateRange[1]) return;

    const newWorkerUserList: WorkerUser[] = [];

    workerUserList.forEach((worker) => {
      if (!worker.bugoAgencyWorkerDetail) return;

      const newFevents: Fevent<
        string,
        FuneralHomeInfo,
        string,
        undefined,
        ShopOrder[]
      >[] = [];

      worker.bugoAgencyWorkerDetail.fevents.forEach((fevent) => {
        if (!fevent.createdAt) return;
        if (
          dateRange[0].toDate() <= fevent.createdAt &&
          dateRange[1].toDate() >= fevent.createdAt
        ) {
          newFevents.push(fevent);
        }
      });

      const newWorker: WorkerUser = {
        ...worker,
        bugoAgencyWorkerDetail: {
          ...worker.bugoAgencyWorkerDetail,
          fevents: newFevents,
        },
      };

      newWorkerUserList.push(newWorker);
    });

    setDateFilteredWorkerList(newWorkerUserList);
  }, [dateRange, workerUserList]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  return (
    <div className="rounded-lg bg-white px-4">
      <div className="flex w-full items-center justify-between py-4">
        <div className="flex items-center justify-start gap-2">
          <p className="font-medium text-gray-600">
            총{' '}
            <span className="font-bold text-black">
              {resultValue.length.toLocaleString()}
            </span>
            명
          </p>
          <div className="flex items-center justify-start gap-2">
            <TextField
              className="border-gray-500"
              type={'search'}
              onChange={onChangeHandler}
              placeholder="검색 상조 지도사명"
            />
          </div>
        </div>
        <DateFilterSearcher
          dateRange={dateRange}
          onChange={(dates) => onDateRangeChange(dates, setDateRange)}
          size="large"
        />
        {/* 소속 필터 */}
        <div className="flex items-center space-x-4 border text-sm theme-bg-1 theme-border-1">
          <div
            className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
              customTheme,
            )}`}
          >
            소속
          </div>
          <div className="flex flex-1 items-center justify-around space-x-4 py-1 pr-2">
            <CheckboxFilter
              filter={selectedTeamTypes}
              setFilter={(checked) => setSelectedTeamTypes(checked)}
            />
          </div>
        </div>
        <CSVLink
          filename={'Expense_Table.csv'}
          data={_.flatMapDeep([csvTableData])}
          className="center-box filled-gray-800 rounded-md px-4 py-2 font-bold"
        >
          <p className="text-white">엑셀 다운로드</p>
        </CSVLink>
      </div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
    </div>
  );
};

export default AgencyAdminRankTable;
