import { Shop } from '@shared/api/shop/shop.interface';
import { callNumberStringFormater } from '@shared/utils/phoneNumberStringFormator';
import { Link } from 'react-router-dom';

interface ShopEnquiryBoxProps {
  shop: Shop;
}
export const ShopEnquiryBox = function ShopEnquiryBox({ shop }: ShopEnquiryBoxProps) {
  return (
    <div className="space-y-8">
      <p className="w-full text-center text-18 font-medium sm:text-20">판매자 문의</p>
      <div className="space-y-8">
        <div className="grid grid-cols-auto-1fr gap-x-4 gap-y-1 text-16">
          <>
            <p className="font-medium">상호</p>
            <p className="text-gray-600">{shop.name}</p>
          </>

          <>
            <p className="font-medium">연락처</p>
            <p className="text-gray-600">{callNumberStringFormater(shop.phoneNumber)}</p>
          </>
          <>
            <p className="font-medium">사업자등록번호</p>
            <p className="text-gray-600">{shop.businessNumber}</p>
          </>
        </div>
        <div className="grid grid-cols-2 gap-2 text-17 font-medium text-gray-700">
          <a
            href={`tel:${shop.phoneNumber}`}
            className="center-box w-full rounded-md border border-gray-400 bg-white p-2"
          >
            <p>전화문의</p>
          </a>
          <Link
            to="/flower-store/enquiry"
            className="center-box w-full rounded-md border border-gray-400 bg-white p-2"
          >
            <p>Q&A문의</p>
          </Link>
        </div>
      </div>
    </div>
  );
};
