import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { AdminNavBar } from '@service/admin/containers/AdminNavBar/AdminNavBar';
import AdminEnquiriesPage from '@service/admin/page/AdminEnquiriesPage';
import { adminNavBarUseAtom } from '@service/bugo/state';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes } from 'react-router-dom';

import AdminFuneralHomeInfoRouter from './AdminFuneralHomeRouter';

const AdminAuthRouter: FC = () => {
  const { isAdmin } = useAuth();
  const useNavBar = useRecoilValue(adminNavBarUseAtom);
  if (isAdmin()) {
    return (
      <div className="max-w-inherit">
        <AdminNavBar />
        <div className={`max-w-inherit ${useNavBar ? 'pt-14' : ''}`}>
          <Routes>
            <Route
              path=""
              element={
                <div className="bg-red-500">
                  <p>관리자용페이지 입니다</p>
                </div>
              }
            />
            <Route path="user">
              <Route
                path="funeralCustomer"
                element={<div>FuneralCustomerManagePage</div>}
              ></Route>
              <Route
                path="flowerSeller"
                element={<div>FlowerSellerManagePage</div>}
              ></Route>
              <Route path="customer" element={<div>CustomerManagePage</div>}></Route>
            </Route>
            <Route path="order" element={<div>OrderManagePage</div>}></Route>
            <Route path="fevent" element={<div>FeventManagePage</div>}></Route>
            <Route path="funeral-home-info/*" element={<AdminFuneralHomeInfoRouter />} />
            <Route path="enquiry">
              <Route path="" element={<div>EnquiryListPage</div>}></Route>
              <Route
                path=":enquiryId/reply"
                element={<div>EnquiryReplyPage</div>}
              ></Route>
            </Route>
            <Route path="enquiries" element={<AdminEnquiriesPage />} />
            <Route path="*" element={<Navigate to=""></Navigate>}></Route>
          </Routes>
        </div>
      </div>
    );
  } else {
    sessionStorage.setItem('redirectUrlAfterAdminLogin', window.location.pathname);
    return <Navigate to="/admin/login" />;
  }
};

export default AdminAuthRouter;
