//* shopOrder를 input으로 받아서 결제정보를 출력함
import { FC } from 'react';

import { ShopOrderForStore } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';

interface Props {
  shopOrder: ShopOrderForStore;
}

export const PaymentInfo: FC<Props> = function PaymentInfo({ shopOrder }) {
  const { Receipt } = Icon();
  return (
    <div className="grid auto-rows-auto border-t border-b text-sm font-normal theme-bg-1 theme-border-1 sm:text-base">
      <div className="grid grid-cols-6  border-b theme-border-1">
        <p className="p-2.5 font-medium theme-bg-6">주문금액</p>
        <div className="col-span-5 flex items-center p-2.5">
          {(
            shopOrder.paymentDetail.paymentByPoint + shopOrder.paymentDetail.paymentByCash
          ).toLocaleString()}
          원
        </div>
      </div>
      <div className="grid grid-cols-6  border-b theme-border-1">
        <div className="flex items-center p-2.5 font-medium theme-bg-6">
          <p>결제금액</p>
        </div>
        <div className="col-span-2 flex items-center p-2.5">
          <span className="font-bold text-warn">
            {shopOrder.paymentDetail.paymentByCash.toLocaleString() + '원'}
          </span>
        </div>
        <p className="flex items-center break-keep p-2.5 font-medium theme-bg-6">
          포인트
        </p>
        <div className="col-span-2 flex items-center p-2.5">
          <span className="font-bold text-myTeal">
            {shopOrder.paymentDetail.paymentByPoint.toLocaleString() + 'P'}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-6 border-b theme-border-1">
        <p className="p-2.5 font-medium theme-bg-6">결제방법</p>
        <div className="col-span-2 flex items-center p-2.5">
          <p>{shopOrder.paymentDetail.method}</p>
        </div>
        <p className="flex items-center break-keep p-2.5 font-medium theme-bg-6">
          결제일
        </p>
        <div className="col-span-2 flex items-center p-2.5">
          {/* TODO: */}
          {/* <p>{dateToCustomShortLocalStringMongo(shopOrder.paymentDetail.purchasedAt, false, true, true, true)}</p> */}
        </div>
      </div>
      {shopOrder.paymentDetail.paymentByCash !== 0 && (
        <div className="grid grid-cols-6 border-b theme-border-1">
          <p className="p-2.5 font-medium theme-bg-6">적립혜택</p>
          <div className="col-span-3 p-2.5">
            <p>
              구매 확정시 적립포인트 &nbsp;
              <span className="font-bold text-myTeal">
                {(shopOrder.paymentDetail.paymentByCash * 0.05).toLocaleString()}P
              </span>
              &nbsp; 적립
            </p>
          </div>
        </div>
      )}
      <div className="grid grid-cols-6 break-keep">
        <p className="p-2.5 font-medium theme-bg-6">결제 영수증</p>
        <div className="col-span-5 p-2.5">
          {/* TODO: */}
          {/* <PopUpLink url={shopOrder.paymentDetail.receiptUrl}> */}
          <Button className="button-rectangle h-10 w-fit gap-1 px-3.5 font-medium text-white theme-bg-14">
            <Receipt className="wh-4 fill-white" />
            <p>영수증 출력</p>
          </Button>
          {/* </PopUpLink> */}
        </div>
      </div>
    </div>
  );
};
