import { FC, useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { useStoreShopItemListByBugoHook } from '@shared/api/shopItem/shopItem.hook';
import { storeShopItemListByBugoStateSelector } from '@shared/api/shopItem/shotItem.selector';
import ClockLoader from '@shared/components/ClockLoader';
import Footer from '@shared/components/Footer';
import { useAuth } from '@shared/state/hooks/useAuth';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import _ from 'lodash';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import StoreItemPreview from '../components/StoreItemPreview';
import { CustomerCallCenterBox } from '../containers/CustomerCallCenterBox/CustomerCallCenterBox';
import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

const StoreMainPage: FC = function StoreMainPage() {
  const navigate = useNavigate();
  const { storeRole } = useAuth();
  const [searchParams] = useSearchParams();
  const paramsString = searchParams.toString();
  const bugoId = searchParams.get('bugoId');

  useStoreTitleHook('', ' ');
  //!bugoId가 없는 경우는 bugoRouter로 보냄
  useEffect(() => {
    if (!bugoId) navigate('/');
  }, [bugoId, navigate]);

  useStoreShopItemListByBugoHook(bugoId ?? '', storeRole());
  const { data, status } = useRecoilValue(storeShopItemListByBugoStateSelector);

  return (
    <div>
      {/* Item Preview List */}
      {status === 'success' && data ? (
        <div className="grid grid-cols-2 gap-x-2 gap-y-2 p-2 pb-9">
          {_.map(data, (item, idx) => (
            <Link
              key={`shop-itme-link-${idx}`}
              to={`/flower-store/item/${item._id}/${paramsString && '?' + paramsString}`}
              state={{
                name: item.name,
              }}
            >
              <StoreItemPreview
                key={`shop-item-preview-${idx}`}
                image={fileBucketUrl(item.shopItemInfo.image.url, 'w400')}
                name={item.name ?? item.shopItemInfo.name}
                priceRetailShow={item.priceRetailShow}
                priceRetail={item.priceRetail}
                tags={item.shopItemInfo.tags ?? item.shopItemInfo.tags}
              />
            </Link>
          ))}
        </div>
      ) : (
        <div className="center-box h-screen-15">
          <ClockLoader />
        </div>
      )}
      <CustomerCallCenterBox />
      <div className="hidden sm:block">
        <Footer />
      </div>
    </div>
  );
};

export default StoreMainPage;
