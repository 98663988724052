import { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { userStateSelector } from '@shared/api/user/user.selector';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { UserCustomTheme } from '@shared/types';
const useCustomThemeHook = function () {
  const userState = useRecoilValue(userStateSelector);
  const setCustomeTheme = useSetRecoilState(customThemeAtom);
  useEffect(() => {
    if (userState.status === 'success' && userState.data) {
      setCustomeTheme(userState.data.info.theme ?? UserCustomTheme.Default);
    }
  }, [setCustomeTheme, userState.data, userState.status]);
};

export default useCustomThemeHook;
