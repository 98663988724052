import { atom, atomFamily } from 'recoil';

import {
  ShopOrderForSeller,
  ShopOrderListByBugo,
  ShopOrderListForStore,
} from '@shared/api/shopOrder/shopOrder.interface';
import { QueryStatus } from 'react-query';

import { ShopOrderForAgencyAdmin, ShopOrderForStore } from './shopOrder.interface';

export const shopOrderListByBugoStatusAtom = atom<QueryStatus>({
  key: 'shopOrderListByBugoStatusAtom',
  default: 'idle',
});

export const shopOrderListByBugoDataAtom = atom<ShopOrderListByBugo | null>({
  key: 'shopOrderListByBugoDataAtom',
  default: null,
});

export const shopOrderListForStoreStatusAtom = atom<QueryStatus>({
  key: 'shopOrderListForStoreStatusAtom',
  default: 'idle',
});

export const shopOrderListForStoreDataAtom = atom<ShopOrderListForStore | null>({
  key: 'shopOrderListForStoreDataAtom',
  default: null,
});

export const shopOrderForStoreStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'shopOrderForStoreStatusAtomFamily',
  default: 'idle',
});

export const shopOrderForStoreDataAtomFamily = atomFamily<
  ShopOrderForStore | null,
  string
>({
  key: 'shopOrderForStoreDataAtomFamily',
  default: null,
});

export const shopOrderListForAgencyAdminStatusAtom = atom<QueryStatus>({
  key: 'shopOrderListForAgencyAdminStatusAtom',
  default: 'idle',
});

export const shopOrderListForAgencyAdminDataAtom = atom<ShopOrderForAgencyAdmin[] | null>(
  {
    key: 'shopOrderListForAgencyAdminDataAtom',
    default: null,
  },
);

//* seller 용 배송관리용
export const shopOrderListForSellerByShopStatusAtomFamily = atomFamily<
  QueryStatus,
  string
>({
  key: 'shopOrderListForSellerByShopStatusAtomFamily',
  default: 'idle',
});

export const shopOrderListForSellerByShopDataAtomFamily = atomFamily<
  ShopOrderForSeller[] | null,
  string
>({
  key: 'shopOrderListForSellerByShopDataAtomFamily',
  default: null,
});
