//* main 페이지에서 사용하는 관리자용 공지사항 컨테이너
import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import AnnouncementCard from '@service/bugo/components/AnnouncementCard';
import { useBugoBrandAnnouncementListhook } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.hook';
import { BugoBrandAnnouncementStatus } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.interface';
import { bugoBrandAnnouncementListStateSelector } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.selector';
import { Button } from '@shared/components/Button';
import ClockLoader from '@shared/components/ClockLoader';
import { Icon } from '@shared/components/icons';
import Tabs from '@shared/components/Tabs';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Link } from 'react-router-dom';

export const AgencyAnnouncement: FC = function AgencyAnnouncement() {
  useBugoBrandAnnouncementListhook();
  const { userProfile } = useAuth();
  const { PlusSolid } = Icon();

  const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];

  const teamTypeList = ['*'].concat(bugoBrand?.bugoAgency?.teamTypes ?? []);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const announcementListState = useRecoilValue(bugoBrandAnnouncementListStateSelector);

  const tabItems = useMemo(() => {
    return teamTypeList.map((teamType) => (teamType === '*' ? '전체' : teamType));
  }, [teamTypeList]);

  const [viewAllList, setViewAllList] = useState<boolean>(false);
  const [listLen, setListLen] = useState<number>(0);

  const selectedTeamType = useMemo(
    () => teamTypeList[selectedTab] ?? '',
    [selectedTab, teamTypeList],
  );

  const announcementList = useMemo(() => {
    if (announcementListState.status === 'success' && announcementListState.data) {
      const visibleList = announcementListState.data.filter(
        (announcement) => announcement.status === BugoBrandAnnouncementStatus.Visible,
      );

      const selectedList = visibleList.filter((item) => {
        if (selectedTeamType === '*') {
          return true;
        } else {
          return item.teamTypes.indexOf(selectedTeamType) !== -1;
        }
      });

      const announcementList = viewAllList ? selectedList : selectedList.slice(0, 2);
      setListLen(selectedList.length);

      return announcementList.map((announcement) => (
        <AnnouncementCard
          key={'announcement-card-' + announcement._id}
          announcement={announcement}
        />
      ));
    } else {
      <div className="center-box min-h-screen-15">
        <ClockLoader />
      </div>;
    }
  }, [
    announcementListState.data,
    announcementListState.status,
    selectedTeamType,
    viewAllList,
  ]);

  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <p className="text-14 font-bold">공지사항</p>
        <Link
          to="bugo-brand/announcement/add"
          state={{
            selectedTeamType,
          }}
        >
          <div className="button cursor-pointer px-2 py-1 theme-bg-1">
            <PlusSolid className="wh-4 mr-1" />
            공지사항생성
          </div>
        </Link>
      </div>
      <Tabs
        items={tabItems}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        className="bg-none"
      ></Tabs>
      {announcementList}
      <div className="flex justify-center">
        {listLen > 2 && (
          <Button onClick={() => setViewAllList((curr) => !curr)} className="button-fold">
            <p>{viewAllList ? '접기' : '전체보기'}</p>
          </Button>
        )}
      </div>
    </div>
  );
};
