import { FC, useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { ShopData } from '@shared/api/shop/shop.interface';
import { sellerShopStateSelectorFamily } from '@shared/api/shop/shop.selector';
import ClockLoader from '@shared/components/ClockLoader';
import { useAuth } from '@shared/state/hooks/useAuth';
import { useParams } from 'react-router-dom';

import SellerShopInfo from '../components/SellerShopInfo';
import ShopCloseButton from '../components/ShopCloseButton';
import SellerShopInfoForm from '../containers/SellerShopInfoForm/SellerShopInfoForm';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

const SellerShopInfoPage: FC = () => {
  useSellerTopNavHook('판매자 정보');
  const { isAdmin } = useAuth();

  const { shopId } = useParams();
  const shopId_ = shopId ?? 'undefined';

  const shopState = useRecoilValue(sellerShopStateSelectorFamily(shopId_));

  const renderInfo = useCallback(
    (shop: ShopData) =>
      isAdmin() ? <SellerShopInfoForm shop={shop} /> : <SellerShopInfo shop={shop} />,
    [isAdmin],
  );

  return (
    <div className="mx-auto w-full max-w-6xl px-4 pb-8 pt-8 sm:w-fit sm:px-6 lg:px-8">
      {shopState.status === 'success' && shopState.data ? (
        <div className="space-y-6">
          <ShopCloseButton shop={shopState.data} />
          {/* Info */}
          {renderInfo(shopState.data)}
        </div>
      ) : (
        <div key="seller-shop-info-page-loader" className="center-box min-h-screen-15">
          <ClockLoader />
        </div>
      )}
    </div>
  );
};

export default SellerShopInfoPage;
