//* 모바일용 부고 등록 내역 조회에서 부고 디테일을 보여주는 모달
import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import RegisteredBugoStatusBar from '@service/bugo/components/RegisteredBugoStatusBar';
import { useWorkerUserByIdHook } from '@shared/api/user/user.hook';
import { WorkerUser } from '@shared/api/user/user.interface';
import { workerUserStateSelector } from '@shared/api/user/user.selector';
import Modal from '@shared/components/Modal';
import PaginationTable from '@shared/containers/PaginationTable/PaginationTable';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { getThisMonthFrom, getThisWeekFrom, getTodayFrom } from '@shared/utils/dateUtils';
import { filterPaidShopOrder } from '@shared/utils/filterPaidShopOrder';
import { formatDate } from '@shared/utils/formatDate';
import {
  mainBgColorGenerator,
  mainColorGenerator,
} from '@shared/utils/mainColorGenerator';

interface Props {
  openId: string;
  workerId: string;
}

const getAllBugosOverFlowers = (worker: WorkerUser | null): string => {
  if (!worker || !worker.bugoAgencyWorkerDetail) return '-';

  let bugos = 0;
  let flowers = 0;

  worker.bugoAgencyWorkerDetail.fevents.forEach((fevent) => {
    flowers += filterPaidShopOrder(fevent.shopOrders).length;
    bugos += 1;
  });

  return bugos === 0 ? '0' : (flowers / bugos).toFixed(2);
};

const getFeventsLenInDateRange = (
  createdAtList: Date[],
  dateFrom: Date,
  dateTo?: Date,
) => {
  const to = dateTo ?? new Date();
  let result = 0;

  createdAtList.forEach((createdAt) => {
    if (dateFrom.getTime() <= createdAt.getTime() && createdAt.getTime() <= to.getTime())
      ++result;
  });

  return result;
};

const WorkerDetailModal: FC<Props> = ({ openId, workerId }: Props) => {
  const customTheme = useRecoilValue(customThemeAtom);

  // userId를 이용해 WorkerUser를 불러오는 hook.
  useWorkerUserByIdHook(workerId);

  const workerUserState = useRecoilValue(workerUserStateSelector(workerId));
  const data = workerUserState.data;

  const bugoLenList = useMemo(() => {
    if (!data?.bugoAgencyWorkerDetail?.fevents) return [null, null, null];

    const createdAtList = data.bugoAgencyWorkerDetail.fevents.map(
      (fevent) => fevent.createdAt,
    );
    const now = new Date();

    return [
      getFeventsLenInDateRange(createdAtList, getTodayFrom(now)),
      getFeventsLenInDateRange(createdAtList, getThisWeekFrom(now)),
      getFeventsLenInDateRange(createdAtList, getThisMonthFrom(now)),
    ];
  }, [data?.bugoAgencyWorkerDetail?.fevents]);

  return (
    <Modal id={openId} openId={openId} className="w-full" position="center">
      <div className="space-y-3 text-xs">
        <h4 className="text-base font-bold">
          {data ? `${data.info.name} 지도사` : '지도사상세정보'}
        </h4>
        {/* Status Bar */}
        <RegisteredBugoStatusBar bugoLenList={bugoLenList} navigateDisabled />
        {/* 지표 */}
        <div className="flex items-center justify-between">
          <h5 className="font-bold">부고등록건수 대비 화환 판매 지표</h5>
          <p
            style={{
              color: mainColorGenerator(customTheme),
            }}
            className={`rounded-xl bg-opacity-10 px-4 py-2 font-bold ${mainBgColorGenerator(
              customTheme,
            )}`}
          >
            {getAllBugosOverFlowers(data)}
          </p>
        </div>
        {/* Pagination Table */}
        {workerUserState.status === 'success' && data && data.bugoAgencyWorkerDetail && (
          <div className="space-y-2">
            <h5 className="font-bold">부고등록현황</h5>
            <PaginationTable
              columns={data.bugoAgencyWorkerDetail.fevents.map((fevent) => ({
                createdAt: fevent.createdAt,
                funeralHomeInfoName: fevent.funeralHomeInfo?.name,
                flowersLen: filterPaidShopOrder(fevent.shopOrders).length,
              }))}
              keyListForOrder={['createdAt', 'funeralHomeInfoName', 'flowersLen']}
              columnNames={{
                createdAt: '등록일자',
                funeralHomeInfoName: '장례식장',
                flowersLen: '화환판매',
              }}
              render={{
                createdAt: ({ data: createdAt }) => (
                  <p>
                    {formatDate(new Date(createdAt), {
                      dateSeparater: '.',
                      timeSeparater: ':',
                      contains: {
                        hours: true,
                        minutes: true,
                      },
                    })}
                  </p>
                ),
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WorkerDetailModal;
