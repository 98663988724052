import { FC } from 'react';

import { BugoData } from '@shared/api/bugo/bugo.interface';
import { formatFeventDateString } from '@shared/utils/formatDate';

interface Props {
  bugo: BugoData;
}

export const ObituaryFeventGrid: FC<Props> = function ObituaryFeventGrid({
  bugo,
}: Props) {
  const deceasedInfo = bugo.fevent.deceasedInfo;
  const fevent = bugo.fevent;
  return (
    <>
      {deceasedInfo.enterance?.date && (
        <>
          <p className="font-bold text-gray-500">입실</p>
          <p className="font-normal">{formatFeventDateString(deceasedInfo.enterance)}</p>
        </>
      )}
      {deceasedInfo.coffinIn?.date && (
        <>
          <p className="font-bold text-gray-500">입관</p>
          <p className="font-normal">{formatFeventDateString(deceasedInfo.coffinIn)}</p>
        </>
      )}
      {deceasedInfo.coffinOut?.date && (
        <>
          <p className="font-bold text-gray-500">발인</p>
          <p className="font-normal">{formatFeventDateString(deceasedInfo.coffinOut)}</p>
        </>
      )}
      {deceasedInfo.cemetery && (
        <>
          <p className="font-bold text-gray-500">장지</p>
          <p className="font-normal">{deceasedInfo.cemetery}</p>
        </>
      )}

      <p className="font-bold text-gray-500">빈소</p>
      <p className="font-normal">{fevent.funeralHomeInfo?.name}</p>

      <p className="font-bold text-gray-500">호실</p>
      <p className="font-normal">{fevent.roomInfo?.name ?? fevent.roomInfoEmbed?.name}</p>

      {(bugo.mournerText || fevent.mournerText) && (
        <>
          <p className="font-bold leading-5 text-gray-500">
            상주
            <br />
            말씀
          </p>
          <p className="font-normal leading-5">
            {bugo.mournerText ? bugo.mournerText : fevent.mournerText}
          </p>
        </>
      )}
    </>
  );
};
