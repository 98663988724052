import { FC } from 'react';

import AgencyAdminBugoBrandAssetManagePage from '@service/bugo/page/AgencyAdminBugoBrandAssetManagePage';
import AgencyAdminBugoStatPage from '@service/bugo/page/AgencyAdminBugoStatPage';
import AgencyAdminFlowerStatPage from '@service/bugo/page/AgencyAdminFlowerStatPage';
import CourtesyAssetContentManagePage from '@service/bugo/page/CourtesyAssetContentManagePage';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const BugoBrandIdRouter: FC = () => {
  const { bugoBrandId } = useParams();
  return (
    <Routes>
      <Route path="flower-stat" element={<AgencyAdminFlowerStatPage />} />
      <Route path="bugo-stat" element={<AgencyAdminBugoStatPage />} />
      <Route path="asset">
        <Route path="" element={<AgencyAdminBugoBrandAssetManagePage />} />
        <Route path="courtesy" element={<CourtesyAssetContentManagePage />}></Route>
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default BugoBrandIdRouter;
