import { FC } from 'react';

import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import RadioInput from '@shared/components/RadioInput';
import { CustomCallback } from '@shared/types';

interface IProps {
  cashAccountList: CashAccount[];
  radioName: string;
  defaultCashAccountId?: string;
  onChange: CustomCallback<CashAccount, any>;
}

const VerifiedCashAccountListTable: FC<IProps> = ({
  cashAccountList,
  radioName,
  defaultCashAccountId,
  onChange,
}: IProps) => {
  return (
    <div className="rounded-lg border-2">
      <div
        className="grid items-center gap-2 break-keep px-3 py-2 text-sm font-medium"
        style={{
          gridTemplateColumns: `minmax(0, 0.6fr) minmax(0, 1fr)  minmax(0, 0.8fr) minmax(0, 3fr)`,
        }}
      >
        <p>선택</p>
        <p>예금주</p>
        <p>은행</p>
        <p>계좌번호</p>
      </div>
      {cashAccountList.map((cashAccount) => {
        return (
          <label key={cashAccount._id}>
            <div
              className="grid items-center gap-2 break-keep border-t px-3 py-2 text-sm font-medium"
              style={{
                gridTemplateColumns: `minmax(0, 0.6fr) minmax(0, 1fr)  minmax(0, 0.8fr) minmax(0, 3fr)`,
              }}
            >
              <RadioInput
                name={radioName}
                frameClassName="px-0.5 py-1"
                onChange={() => onChange(cashAccount)}
                defaultChecked={cashAccount._id === defaultCashAccountId}
              />
              <p>{cashAccount.name}</p>
              <p>{cashAccount.bank}</p>
              <p>{cashAccount.account}</p>
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default VerifiedCashAccountListTable;
