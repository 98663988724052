import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

export const postImage = async (imageFile: File, assetRole: LowerRole) => {
  try {
    const form = new FormData();
    form.append('image', imageFile);
    form.append('body', JSON.stringify({ name: imageFile.name }));

    const { data: image } = await api_.post(`${assetRole}/image/file`, form);
    const imageId = image?._id;
    return imageId;
  } catch (error) {
    console.log(`upload fail: ${JSON.stringify((error as any).message)}`);
    throw new Error('upload fail');
  }
};

export const postImages = async (imageFileList: FileList, assetRole: LowerRole) => {
  const imageIdList: string[] = [];
  try {
    for (const [key, value] of Object.entries(imageFileList)) {
      const imageId = await postImage(value, assetRole);
      imageIdList.push(imageId);
    }
  } catch (error) {
    console.log(`upload fail: ${JSON.stringify((error as any).message)}`);
    throw new Error('upload fail');
  }

  return imageIdList;
};
