import { selector, selectorFamily } from 'recoil';

import {
  shopOrderListByBugoDataAtom,
  shopOrderListByBugoStatusAtom,
  shopOrderListForAgencyAdminDataAtom,
  shopOrderListForAgencyAdminStatusAtom,
  shopOrderListForSellerByShopDataAtomFamily,
  shopOrderListForSellerByShopStatusAtomFamily,
  shopOrderListForStoreDataAtom,
  shopOrderListForStoreStatusAtom,
} from '@shared/api/shopOrder/shopOrder.atom';
import {
  ShopOrderForSeller,
  ShopOrderListByBugoState,
  ShopOrderListForAgencyAdminState,
} from '@shared/api/shopOrder/shopOrder.interface';
import { QueryDataState } from '@shared/interfaces';

import {
  shopOrderForStoreDataAtomFamily,
  shopOrderForStoreStatusAtomFamily,
} from './shopOrder.atom';
import {
  ShopOrderForStoreState,
  ShopOrderListForStoreState,
} from './shopOrder.interface';

export const shopOrderListByBugoStateSelector = selector<ShopOrderListByBugoState>({
  key: 'shopOrderListByBugoStateSelector',
  get: ({ get }) => {
    return {
      status: get(shopOrderListByBugoStatusAtom),
      data: get(shopOrderListByBugoDataAtom),
    };
  },
});

export const shopOrderListForStoreStateSelector = selector<ShopOrderListForStoreState>({
  key: 'shopOrderListForStoreStateSelector',
  get: ({ get }) => {
    return {
      status: get(shopOrderListForStoreStatusAtom),
      data: get(shopOrderListForStoreDataAtom),
    };
  },
});

export const shopOrderForStoreStateSelectorFamily = selectorFamily<
  ShopOrderForStoreState,
  string
>({
  key: 'shopOrderForStoreStateSelectorFamily',
  get:
    (shopOrderId) =>
    ({ get }) => {
      return {
        status: get(shopOrderForStoreStatusAtomFamily(shopOrderId)),
        data: get(shopOrderForStoreDataAtomFamily(shopOrderId)),
      };
    },
});

export const shopOrderListForAgencyAdminStateSeletor =
  selector<ShopOrderListForAgencyAdminState>({
    key: 'shopOrderListForAgencyAdminStateSeletor',
    get: ({ get }) => {
      return {
        status: get(shopOrderListForAgencyAdminStatusAtom),
        data: get(shopOrderListForAgencyAdminDataAtom),
      };
    },
  });

//* seller용 배송관리용
export const shopOrderListForSellerByShopStateSelectorFamily = selectorFamily<
  QueryDataState<ShopOrderForSeller[]>,
  string
>({
  key: 'shopOrderListForSellerByShopStateSelectorFamily',
  get:
    (shopOrderId) =>
    ({ get }) => {
      return {
        status: get(shopOrderListForSellerByShopStatusAtomFamily(shopOrderId)),
        data: get(shopOrderListForSellerByShopDataAtomFamily(shopOrderId)),
      };
    },
});
