import { FC, useCallback, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Bootpay } from '@bootpay/client-js';
import { useBugoHook } from '@shared/api/bugo/bugo.hook';
import { bugoStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import { shopItemForStoreStateSelectorFamily } from '@shared/api/shopItem/shotItem.selector';
import {
  createShopOrder,
  deleteShopOrderById,
  verifyShopOrderById,
} from '@shared/api/shopOrder/shopOrder.controller';
import { ShopOrderCreateDto } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import ClockLoader from '@shared/components/ClockLoader';
import { CheckOutState } from '@shared/interfaces/bootpay.interface';
import { notificateError } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { formatDate } from '@shared/utils/formatDate';
import { formatPrice } from '@shared/utils/formatPrice';
import _ from 'lodash';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import PhraseInfoInputs from '../components/PhraseInfoInputs';
import ReceiverInfoInputs from '../components/ReceiverInfoInputs';
import SenderInfoInputs from '../components/SenderInfoInputs';
import ShopItemCounter from '../components/ShopItemCounter';
import { useStoreTitleHook } from '../hooks/useStoreTitleHook';
import { IShopItemFormData, ShopItemFormData } from '../state/shopItemCheckout.interface';
import { getBootpayRequestPaymentDto } from '../utils/getBootpayRequestDto';
import { getEstimateDeliveredAt } from '../utils/getEstimateDeliveredAt';
import { getShopOrderDto } from '../utils/getShopOrderDto';

const ShopItemCheckoutPage: FC = () => {
  // Params
  const { itemId } = useParams();
  const [searchParams] = useSearchParams();
  const bugoId = searchParams.get('bugoId');

  // Hooks
  useStoreTitleHook('주문/결제', ' ');

  useBugoHook(bugoId ?? '', !bugoId);
  const navigate = useNavigate();

  // States
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [checkoutState, setCheckoutState] = useState(CheckOutState.IDLE); // 주문결제성공 페이지로 넘어가기위한 state IDLE DOING DONE
  const [shopOrderIdState, setShopOrderIdState] = useState<string | undefined>();
  const [requestState, setRequestState] = useState<boolean>(false); // bootpay request call trigger
  const [bootpayData, setBootpayData] = useState<IShopItemFormData>();

  // recoil
  const { storeRole, userProfile, signupOrLoginStoreGuest } = useAuth();
  const { data: shopItemData, status: shopItemStatus } = useRecoilValue(
    shopItemForStoreStateSelectorFamily(itemId ?? ''),
  );
  const { data: bugoData, status: bugoStatus } = useRecoilValue(
    bugoStateSelectorFamily(bugoId ?? ''),
  );
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  // Form
  const { register, control, handleSubmit, setValue } = useForm<IShopItemFormData>();
  const itemCount = useWatch({
    control,
    name: ShopItemFormData.ItemCount,
    defaultValue: 1,
  });
  const usedPoint = useWatch({
    control,
    name: ShopItemFormData.UsedPoint,
    defaultValue: 0,
  });
  const isAgreed = useWatch({
    control,
    name: ShopItemFormData.IsAgreed,
    defaultValue: false,
  });

  useEffect(() => {
    if (bugoData) {
      setValue(
        ShopItemFormData.ReceiverAddress,
        bugoData.fevent.funeralHomeInfo.address +
          ', [' +
          bugoData.fevent.funeralHomeInfo.name +
          ', ' +
          (bugoData.fevent.roomInfo?.name ?? bugoData.fevent.roomInfoEmbed?.name ?? '') +
          ']',
      );
      setValue(ShopItemFormData.ReceiverName, bugoData.member.fullName + '상주님');
    }
  }, [bugoData, setValue]);

  useEffect(() => {
    if (userProfile) {
      console.log('user');
      setValue(ShopItemFormData.SenderName, userProfile.info.name ?? '');
      setValue(ShopItemFormData.SenderTel, userProfile.info.phoneNumber ?? '');
      // setValue(ShopItemFormData.SenderPassword, userProfile.info.phoneNumber);
    }
  }, [setValue, userProfile]);

  //! 4. 결제 성공 및 실패에 따라서 error page, success page로 redirect
  // 결제 성공 시 Redirect.
  useEffect(() => {
    if (checkoutState === CheckOutState.DONE && !_.isNil(shopOrderIdState)) {
      navigate(
        `/flower-store/order/${shopOrderIdState}/payment/?success=${isSuccess}&bugoId=${bugoId}`,
      );
    }
  }, [navigate, checkoutState, shopOrderIdState, isSuccess, bugoId]);

  //* utils
  const doneHandle = useCallback(
    async (data: any, shopOrderId: string) => {
      try {
        const shopOrder_ = {
          _id: shopOrderId,
          receiptId: data.receipt_id,
        };
        const isValid = await verifyShopOrderById(shopOrder_._id, storeRole()); // /success/:id

        if (isValid) {
          setIsSuccess(true);
          setShopOrderIdState(shopOrderId); // recoil state 바꾸는 함수
          setCheckoutState(CheckOutState.DONE);
        } else {
          throw Error('유효하지 않은 결제 시도로 인한 취소 처리');
        }
      } catch (err: any) {
        console.error(err);
        setCheckoutState(CheckOutState.IDLE);
        notificateError(
          notifiacationInstance,
          `결제 실패\n${err.response?.data?.message}`,
        );
      }
    },
    [notifiacationInstance, storeRole],
  );

  const readyHandle = async (data: any) => {
    console.log(data);
  };

  //* utils

  const onSubmit: SubmitHandler<IShopItemFormData> = useCallback(
    async (data) => {
      setBootpayData(data);

      if (_.isNil(userProfile)) {
        try {
          // guest signup logic
          // console.log('user 가입 시켜야함');
          const guestSignupData = {
            name: data.senderName,
            phoneNumber: data.senderTel,
            password: data.senderPassword,
          };
          await signupOrLoginStoreGuest(guestSignupData);
          setRequestState(true);
        } catch (err) {
          console.error(err);
        }
      } else {
        setRequestState(true);
      }
    },
    [signupOrLoginStoreGuest, userProfile],
  );

  //TODO: bootpay 실제 로직
  const onBootpayRequest = useCallback(async () => {
    // 필요한 데이터 없을 시 return
    console.log('userProfile: ', userProfile);
    console.log('bootpayData: ', bootpayData);
    console.log('shopItemData: ', shopItemData);
    console.log('bugoData: ', bugoData);
    if (
      _.isNil(userProfile) ||
      _.isNil(bootpayData) ||
      _.isNil(shopItemData) ||
      _.isNil(bugoData)
      // !data.isAgreed
    )
      return;

    const data = bootpayData;
    console.log('data: ', data);

    //! 0. shopOrder dto 만들기
    const shopOrderDto: ShopOrderCreateDto = getShopOrderDto({
      formData: data,
      shopItem: shopItemData,
      bugo: bugoData,
      userId: userProfile._id,
    });

    // console.log('shopOrderDto: ', shopOrderDto);

    try {
      //! 1. backend에 shopOrder 생성하기
      const { data: shopOrder } = await createShopOrder(shopOrderDto, storeRole());

      try {
        //! 2. checkoutState 변경하기(결제중)
        setCheckoutState(CheckOutState.DOING);

        // console.log('bootpayRequest: ', {
        //   formData: data,
        //   shopOrder,
        //   shopItem: shopItemData,
        //   userProfile,
        // });

        //! 3. shopOrder data, register data, user data 참조하여 bootpay payment callback 실행
        const paymentResponse = await Bootpay.requestPayment(
          getBootpayRequestPaymentDto({
            formData: data,
            shopOrder,
            shopItem: shopItemData,
            userProfile,
          }),
        );

        let confirmedData;
        let enable = true;

        switch (paymentResponse.event) {
          case 'issued':
            // 가상계좌 입금 완료 처리
            await readyHandle(paymentResponse.data);
            break;
          case 'done':
            //!   3-2. 결제가 완료되었을 경우 checkoutState를 변경(완료)
            await doneHandle(paymentResponse.data, shopOrder._id);
            break;
          // * confirm 사실 안씀
          case 'confirm':
            enable = true; // 재고 수량 관리 로직 혹은 다른 처리
            if (enable) {
              confirmedData = await Bootpay.confirm(); //결제를 승인한다
              if (confirmedData.event === 'done') {
                //결제 성공
              } else if (confirmedData.event === 'error') {
                //결제 승인 실패
              }
            } else {
              Bootpay.destroy(); // 조건이 맞지 않으면 결제 창을 닫고 결제를 승인하지 않는다.
            }

            /**
             * 2. 서버 승인을 하고자 할때
             * // requestServerConfirm(); //예시) 서버 승인을 할 수 있도록  API를 호출한다. 서버에서는 재고확인과 로직 검증 후 서버승인을 요청한다.
             * Bootpay.destroy(); //결제창을 닫는다.
             */
            break;
          case 'close':
          // 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
          // console.log('[bootpay.request.close]', paymentResponse);
        }
      } catch (error) {
        //!   3-1. 결제가 실패했을 경우, 결과에 맞게 backend shopOrder 상태 반환 또는 삭제
        switch ((error as any).event) {
          case 'cancel':
            // 사용자가 결제창을 닫을때 호출
            console.error(error);
            setCheckoutState(CheckOutState.IDLE);
            await deleteShopOrderById(shopOrder._id, storeRole());
            break;
          case 'error':
            // 결제 승인 중 오류 발생시 호출
            console.error(error);
            // await deleteShopOrderById(shopOrder._id, storeRole());
            setShopOrderIdState(shopOrder._id);
            setCheckoutState(CheckOutState.DONE);
            break;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [userProfile, bootpayData, shopItemData, bugoData, storeRole, doneHandle]);

  useEffect(() => {
    if (requestState) {
      onBootpayRequest();
      setRequestState(false);
    }
  }, [requestState, onBootpayRequest, setRequestState]);

  return (
    <div>
      {shopItemStatus === 'success' && shopItemData ? (
        <form onSubmit={handleSubmit(onSubmit)} className="relative pb-12">
          <div className="p-4 text-sm">
            {/* 화환 정보 */}
            <div className="flex justify-between border-b border-gray-300 p-2 pt-0">
              {/* 사진 / 도착 예정일 / 개수 */}
              <div className="flex">
                {/* 상품 사진 */}
                <img
                  src={fileBucketUrl(shopItemData.shopItemInfo.image.url, 'w400')}
                  alt="Flower Image"
                  className="aspect-square w-20 object-cover object-center"
                />
                <div className="pl-2">
                  {/* 상품명 */}
                  <h4 className="font-bold">{shopItemData.shopItemInfo.name}</h4>
                  {/* 도착 예정일 */}
                  <div className="pt-1 text-xs theme-text-main">
                    {formatDate(getEstimateDeliveredAt(), {
                      dateSeparater: '/',
                      contains: {
                        year: false,
                        day: true,
                      },
                    })}
                    도착 보장
                  </div>
                  {/* 상품 개수 */}
                  <div className="w-[84px] pt-4">
                    <ShopItemCounter
                      control={control}
                      name={ShopItemFormData.ItemCount}
                    />
                  </div>
                </div>
              </div>
              {/* 가격 정보 */}
              <div className="mt-auto text-right">
                {/* 원래 가격 */}
                <div className="text-sm leading-4 text-gray-500 line-through">
                  {formatPrice(shopItemData.priceRetailShow * itemCount)}원
                </div>
                {/* 할인 적용 가격 */}
                <div className="flex items-end">
                  <h3 className="text-xl font-bold leading-6">
                    {formatPrice(shopItemData.priceRetail * itemCount)}
                  </h3>
                  <span className="text-sm leading-6">원</span>
                </div>
              </div>
            </div>
            {/* Contents */}
            <div className="space-y-4 py-4">
              {/* 주문자 정보 */}
              <SenderInfoInputs
                register={register}
                control={control}
                names={{
                  senderName: ShopItemFormData.SenderName,
                  senderPassword: ShopItemFormData.SenderPassword,
                  senderTel: ShopItemFormData.SenderTel,
                }}
              />
              {/* 수령인 정보 */}
              <ReceiverInfoInputs
                register={register}
                names={{
                  receiverName: ShopItemFormData.ReceiverName,
                  receiverAddress: ShopItemFormData.ReceiverAddress,
                }}
              />
              {/* 문구 작성 */}
              <PhraseInfoInputs
                register={register}
                control={control}
                names={{
                  phraseSender: ShopItemFormData.PhraseSender,
                  phraseCondolence: ShopItemFormData.PhraseCondolence,
                }}
              />
              {/* 결제 정보 */}
              <div>
                <h4 className="mb-3 font-bold">결제 정보</h4>
                <div className="flex flex-col space-y-3 px-4 py-3 theme-bg-1">
                  {/* 상품 금액 */}
                  <div className="flex items-center justify-between">
                    <span className="theme-text-8">상품 금액 ({itemCount}개)</span>
                    <span>{formatPrice(shopItemData.priceRetailShow * itemCount)}원</span>
                  </div>
                  {/* 할인 금액 */}
                  <div className="flex items-center justify-between">
                    <span className="theme-text-8">할인 금액</span>
                    <span className="text-myTeal">
                      {formatPrice(
                        (shopItemData.priceRetail - shopItemData.priceRetailShow) *
                          itemCount,
                      )}
                      원
                    </span>
                  </div>
                  {/* 포인트사용 */}
                  {usedPoint !== 0 && (
                    <div className="flex items-center justify-between">
                      <span className="theme-text-8">포인트사용</span>
                      <span className="text-myTeal">-{formatPrice(usedPoint)}원</span>
                    </div>
                  )}
                  {/* 배송비 */}
                  <div className="flex items-center justify-between">
                    <span className="theme-text-8">배송비</span>
                    <div className="flex items-center justify-end gap-x-2">
                      <div className="theme-text-main">
                        {formatDate(getEstimateDeliveredAt(), {
                          dateSeparater: '/',
                          contains: {
                            year: false,
                            day: true,
                          },
                        })}
                        도착 보장
                      </div>
                      <span>무료배송</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* 동의 확인 */}
              <div>
                <div className="flex justify-between">
                  <h4 className="pb-3 font-bold">개인정보 취급방침에 대한 동의</h4>
                </div>
                <p className="p-4 pt-2 theme-text-8 theme-bg-1">
                  <p className="flex h-12 items-center justify-start py-2 text-sm">
                    아래 내용을 확인하였으며 결제에 동의합니다.
                  </p>
                  <span className="block font-bold leading-7 underline">
                    개인정보 취급방침
                  </span>
                  <span className="whitespace-pre-line break-keep text-xs leading-[18px]">
                    가온프라임(주)는 통신판매중개자로서, 입점판매자가 등록한 상품 정보 및
                    거래에 대해 가온프라임(주)는 일체의 책임을 지지 않습니다.
                  </span>
                </p>
              </div>
            </div>
          </div>
          {/* 결제 박스 */}
          <div className="fixed left-0 right-0 bottom-0 mx-auto flex max-w-screen-md items-center gap-x-6 border-t py-2 px-2 theme-bg-1 theme-border-5">
            {/* 총 할인금액 */}
            <div className="pl-4 text-right text-xs">
              <div className="theme-text-8">총 할인금액</div>
              <div className="font-bold text-myTeal">
                {formatPrice(
                  -1 *
                    itemCount *
                    (shopItemData.priceRetailShow - shopItemData.priceRetail),
                )}
                원
              </div>
            </div>
            {/* 결제 버튼 */}
            <Button
              type="submit"
              className="button-rectangle flex-1 text-white theme-bg-main"
            >
              {formatPrice(shopItemData.priceRetail * itemCount - usedPoint)}원 결제하기
            </Button>
          </div>
        </form>
      ) : (
        // TODO: 로더 변경.
        <div>
          <ClockLoader />
        </div>
      )}
    </div>
  );
};

export default ShopItemCheckoutPage;
