//*Seller가 해당 fevent의 상품 구매하도록

import { FC, useMemo } from 'react';

import { FeventByShop } from '@shared/api/fevent/fevent.interface';
import ClockLoader from '@shared/components/ClockLoader';
import Modal from '@shared/components/Modal';

//* 전화주문으로 shopOrder 생성하기 위한 모달
interface Props {
  fevent: FeventByShop | null;
  openId: string;
}

export const SellerShopOrderCreateModal: FC<Props> = function SellerShopOrderCreateModal({
  fevent,
  openId,
}: Props) {
  const innerContent = useMemo(() => {
    if (fevent) {
      return <div>{fevent.deceasedInfo.name}</div>;
    } else {
      return (
        <div>
          <ClockLoader />
        </div>
      );
    }
  }, [fevent]);

  return <Modal openId={openId}>{innerContent}</Modal>;
};
