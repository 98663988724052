import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  createCourtesyAsset,
  updateCourtesyAsset,
} from '@shared/api/courtesyAsset/courtesyAsset.controller';
import { courtesyAssetListByBugoBrandHookUrl } from '@shared/api/courtesyAsset/courtesyAsset.hook';
import { CourtesyAssetData } from '@shared/api/courtesyAsset/courtesyAsset.interface';
import { postImage } from '@shared/api/uploadImage';
import { SingleImageUploader } from '@shared/components/ImageUploader';
import Modal from '@shared/components/Modal';
import { TextField } from '@shared/components/TextField';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { uploadFileAtomFamily } from '@shared/state/atom/file.atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { UpsertMode } from '@shared/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import {
  CourtesyAssetUpsertFormEnum,
  CourtesyAssetUpsertFormInputs,
} from './CourtesyAssetUpsertForm.interface';

interface Props {
  openId: string;
  mode: UpsertMode;
  courtesyAsset?: CourtesyAssetData;
}

/**
 *  답례글 에셋 UpsertModal
 *  openId
 *  mode: UpsertMode 수정, 등록
 *  courtesyAsset: 수정시 기본 값
 * */
export const CourtesyAssetUpsertModal: FC<Props> = function CourtesyAssetUpsertModal({
  openId,
  mode,
  courtesyAsset,
}: Props) {
  const { assetRole, bugoBrandId, bugoRole } = useAuth();
  const { register, handleSubmit } = useForm<CourtesyAssetUpsertFormInputs>({
    defaultValues: {
      [CourtesyAssetUpsertFormEnum.Name]: courtesyAsset?.name,
      [CourtesyAssetUpsertFormEnum.BugoBrand]: courtesyAsset?.bugoBrand,
      [CourtesyAssetUpsertFormEnum.Id]: courtesyAsset?._id,
      [CourtesyAssetUpsertFormEnum.TextBox_X0]: courtesyAsset?.textBox.x0,
      [CourtesyAssetUpsertFormEnum.TextBox_X1]: courtesyAsset?.textBox.x1,
      [CourtesyAssetUpsertFormEnum.TextBox_Y0]: courtesyAsset?.textBox.y0,
      [CourtesyAssetUpsertFormEnum.TextBox_Y1]: courtesyAsset?.textBox.y1,
      [CourtesyAssetUpsertFormEnum.BackgroundImage]: courtesyAsset?.backgroundImage._id,
    },
  });
  const onCloseModal = () => {
    setOpen(false);
    history.back();
  };
  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const { isLoading, mutateAsync } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        courtesyAssetListByBugoBrandHookUrl(bugoBrandId, bugoRole()),
      );
      notificateSuccess(notifiacationInstance, '저장완료');
      onCloseModal();
    },
    onError: () => {
      notificateError(notifiacationInstance, '에러');
    },
  });
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const modalTitle =
    mode === UpsertMode.Insert ? '답례글이미지 추가' : '답례글이미지 수정';

  const uploadImageFile = useRecoilValue(uploadFileAtomFamily(openId));

  const onSubmit: SubmitHandler<CourtesyAssetUpsertFormInputs> = async (data) => {
    if (uploadImageFile) {
      data[CourtesyAssetUpsertFormEnum.BackgroundImage] = await postImage(
        uploadImageFile,
        assetRole(),
      );
    }

    data[CourtesyAssetUpsertFormEnum.BugoBrand] = bugoBrandId;

    //* 필수정보 확인
    if (
      !data[CourtesyAssetUpsertFormEnum.Name] ||
      !data[CourtesyAssetUpsertFormEnum.BugoBrand] ||
      !data[CourtesyAssetUpsertFormEnum.BackgroundImage]
    ) {
      alert(`필수정보를 모두 입력해주세요`);
      return;
    }

    try {
      //업데이트시
      if (mode === UpsertMode.Update) {
        {
          if (courtesyAsset) {
            const courtesyAssetUpdate = {
              ...data,
              _id: courtesyAsset._id,
            };
            await updateCourtesyAsset(courtesyAssetUpdate, bugoRole());
          }
        }
      }
      //등록시
      else {
        const courtesyAssetCreate = {
          ...data,
          textBox: {
            x0: data['textBox.x0'],
            x1: data['textBox.x1'],
            y0: data['textBox.y0'],
            y1: data['textBox.y1'],
          },
        };
        await createCourtesyAsset(courtesyAssetCreate, bugoRole());
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal openId={openId}>
      <div className="space-y-4">
        <p className="bugo-h1">{modalTitle}</p>
        <TextField
          className="w-full"
          {...register(CourtesyAssetUpsertFormEnum.Name)}
          label="이름"
        ></TextField>
        <SingleImageUploader uploadFileAtomId={openId}></SingleImageUploader>
        <div>
          <div className="flex gap-2">
            <TextField
              className="w-full"
              {...register(CourtesyAssetUpsertFormEnum.TextBox_X0)}
              label="box x0"
            ></TextField>
            <TextField
              className="w-full"
              {...register(CourtesyAssetUpsertFormEnum.TextBox_X1)}
              label="box x1"
            ></TextField>
          </div>
          <div className="flex gap-2">
            <TextField
              className="w-full"
              {...register(CourtesyAssetUpsertFormEnum.TextBox_Y0)}
              label="box y0"
            ></TextField>
            <TextField
              className="w-full"
              {...register(CourtesyAssetUpsertFormEnum.TextBox_Y1)}
              label="box y1"
            ></TextField>
          </div>
        </div>
        <div className="pt-5 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 sm:pt-8">
          <button
            disabled={isLoading}
            type="button"
            className={`inline-flex w-full justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none sm:col-start-2`}
            onClick={async (e) => {
              await mutateAsync(handleSubmit(onSubmit)(e));
            }}
          >
            저장
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:col-start-1 sm:mt-0"
            onClick={onCloseModal}
          >
            취소
          </button>
        </div>
      </div>
    </Modal>
  );
};
