//*관리자용으로 bugoId없이 fevent만으로 부고문자 보는용

import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { feventStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import ClockLoader from '@shared/components/ClockLoader';
import { useParams } from 'react-router-dom';

import { ObituaryFuneralHomeInfoUnit } from '../containers/Obituary/ObituaryFuneralHomeInfoUnit';
import { useTitleHook } from '../hooks/useTitleHook';

const FeventOituaryAdminPreviewPage: FC = function FeventOituaryAdminPreviewPage() {
  useTitleHook('부고지도사항 미리보기', ' ', undefined);
  const { feventId } = useParams();

  const feventState = useRecoilValue(feventStateSelectorFamily(`${feventId}`));

  const feventObituaryPreview = useMemo(() => {
    if (feventState.status === 'success' && feventState.data) {
      const funeralHomeInfo = feventState.data.funeralHomeInfo;
      return (
        <div>
          <ObituaryFuneralHomeInfoUnit funeralHomeInfo={funeralHomeInfo} />
        </div>
      );
    } else {
      return (
        <div className="center-box h-screen-15">
          <ClockLoader />
        </div>
      );
    }
  }, [feventState.data, feventState.status]);

  return <div>{feventObituaryPreview}</div>;
};

export default FeventOituaryAdminPreviewPage;
