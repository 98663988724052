import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { BugoStatRangeQueryEnum } from '@service/bugo/page/AgencyAdminBugoStatMobilePage';
import { FeventByBugoBrand } from '@shared/api/fevent/fevent.interface';
import { User } from '@shared/api/user/user.interface';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { DatePicker } from '@shared/containers/DatePicker/DatePicker';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { getDefaultDateFrom, getTodayFrom, getTodayTo } from '@shared/utils/dateUtils';
import { filterPaidShopOrder } from '@shared/utils/filterPaidShopOrder';
import { formatDate } from '@shared/utils/formatDate';
import { getRoughRegion } from '@shared/utils/getRoughRegion';
import { mainBgColorGenerator } from '@shared/utils/mainColorGenerator';
import { Table, TableColumnsType } from 'antd';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';

import BugoDetailModalOpener from '../BugoDetailModalOpener/BugoDetailModalOpener';
import WorkerDetailModalOpener from '../WorkerDetailModalOpener/WorkerDetailModalOpener';
import { AgencyAdminBugoStatusBar } from './AgencyAdminBugoStatusBar';

interface IProps {
  feventList: FeventByBugoBrand[];
  teamTypesFilter: CheckBoxFilter;
  regionsFilter: CheckBoxFilter;
  range: BugoStatRangeQueryEnum | null;
  searchValue: string;
}

type FeventTableData = {
  id: string;
  index: number;
  user: User;
  funeralHomeName: string;
  region: string;
  createdAt: Date | '';
  orderListLen: number;
};

const AgencyAdminBugoListTableMobile: FC<IProps> = ({
  feventList,
  teamTypesFilter,
  regionsFilter,
  range,
  searchValue,
}: IProps) => {
  const customTheme = useRecoilValue(customThemeAtom);

  const [dateFrom, setDateFrom] = useState<Date | null>(
    range ? getDefaultDateFrom(range) : null,
  );
  const [dateTo, setDateTo] = useState<Date | null>(range ? new Date() : null);

  const [dateFilteredFeventList, setDateFilteredFeventList] = useState<
    FeventByBugoBrand[]
  >(feventList ?? []);

  // TeamType Filtering
  const [selectedTeamTypes, setSelectedTeamTypes] = useState(teamTypesFilter);

  const teamTypesFilteredFeventList = useMemo(() => {
    return dateFilteredFeventList.filter(
      (fevent) => selectedTeamTypes[fevent.user.bugoAgencyWorkerDetail?.teamType ?? ''],
    );
  }, [dateFilteredFeventList, selectedTeamTypes]);

  // Region Filtering
  const [selectedRegions, setSelectedRegions] = useState(regionsFilter);

  const regionsFilteredFeventList = useMemo(() => {
    return teamTypesFilteredFeventList.filter(
      (fevent) =>
        selectedRegions[
          getRoughRegion(fevent.funeralHomeInfo?.address?.split(' ')[0] ?? '')
        ],
    );
  }, [teamTypesFilteredFeventList, selectedRegions]);

  // Search
  const resultValue = useMemo(() => {
    const searcher = new FuzzySearch(regionsFilteredFeventList, [
      'user.info.name',
      'funeralHomeInfo.name',
      'funeralHomeInfo.address',
      'registerNumber',
      'user.bugoAgencyWorkerDetail.teamType',
      'user.bugoAgencyWorkerDetail.teamName',
    ]);
    return searcher.search(searchValue);
  }, [regionsFilteredFeventList, searchValue]);

  const tableData: FeventTableData[] = useMemo(() => {
    return _.map(resultValue as FeventByBugoBrand[] | null, (fevent, index) => {
      return {
        id: fevent._id,
        index: index,
        user: fevent.user,
        region: fevent.funeralHomeInfo.address.split(' ')[0] ?? '',
        funeralHomeName: fevent.funeralHomeInfo?.name,
        createdAt: fevent.createdAt,
        orderListLen: filterPaidShopOrder(fevent.shopOrders).length,
      };
    });
  }, [resultValue]);

  // dateRange 적용.
  useEffect(() => {
    if (!feventList) return;

    const rangeFilteredList = feventList.filter((fevent) => {
      if (!fevent.createdAt) return false;
      const createdAt = new Date(fevent.createdAt).getTime();

      if (dateFrom && dateTo) {
        return dateFrom.getTime() <= createdAt && createdAt <= dateTo.getTime();
      }

      // dateRange 적용 안되어 있으면 다 보여주기.
      if (!dateFrom && !dateTo) return true;

      // 한 쪽만 적용되어 있으면 false.
      return false;
    });

    setDateFilteredFeventList(rangeFilteredList);
  }, [dateFrom, dateTo, feventList]);

  const columns: TableColumnsType<FeventTableData> = [
    {
      title: <div className="break-keep">등록일</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (value: Date) => {
        return (
          <p className="text-xs text-gray-600">
            {formatDate(value, {
              contains: { year: false, date: true },
              dateSeparater: '/',
            })}
          </p>
        );
      },
    },
    {
      width: '15%',
      title: <div className="break-keep">소속</div>,
      dataIndex: 'user',
      key: 'teamType',
      align: 'center',
      render: (user: User) => {
        return <p className="break-keep text-xs">{getAgencyWorkerTeamName(user)}</p>;
      },
    },
    {
      width: '15%',
      title: <div className="break-keep">지도사</div>,
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      render: (user: User) => {
        return (
          <WorkerDetailModalOpener workerName={user.info.name} workerId={user._id} />
        );
      },
    },
    // {
    //   width: '15%',
    //   title: <div className="break-keep">지역</div>,
    //   dataIndex: 'region',
    //   key: 'region',
    //   align: 'center',
    //   render: (region: string) => {
    //     return <p className="break-keep text-xs">{region}</p>;
    //   },
    // },
    {
      width: '35%',
      title: <div className="break-keep">장례식장</div>,
      dataIndex: 'funeralHomeName',
      key: 'funeralHomeName',
      align: 'center',
      render: (funeralHomeName) => {
        return <p className="text-xs">{funeralHomeName.split('장례')[0]}</p>;
      },
    },
    {
      width: '15%',
      title: <div className="break-keep">화환 판매</div>,
      key: 'sentBugosLen/orderListLen',
      align: 'center',
      render: (value: FeventTableData) => {
        const { orderListLen } = value;
        return (
          <p className="text-xs text-gray-600">{`${orderListLen.toLocaleString()}`}</p>
        );
      },
    },
    {
      width: '15%',
      title: '상세',
      key: 'bugo modal button',
      align: 'center',
      render: (value: FeventTableData) => {
        return (
          <div className="center-box">
            <BugoDetailModalOpener
              fevent={feventList.find((fevent) => fevent._id === value.id) ?? null}
            />
          </div>
        );
      },
    },
  ];

  const onDateFromChange = (date: Date | null) => {
    // 설정된 시작일이 종료일보다 클 경우 switch.
    if (dateTo && date && dateTo.getTime() < date.getTime()) {
      setDateFrom(getTodayFrom(dateTo));
      setDateTo(getTodayTo(date));
      return;
    }

    setDateFrom(date ? getTodayFrom(date) : null);
  };

  const onDateFromTo = (date: Date | null) => {
    // 설정된 종료일이 시작일보다 작을 경우 switch.
    if (dateFrom && date && dateFrom.getTime() > date.getTime()) {
      setDateTo(getTodayTo(dateFrom));
      setDateFrom(getTodayFrom(date));
      return;
    }

    setDateTo(date ? getTodayTo(date) : null);
  };

  return (
    <div className="space-y-2 px-2">
      {/* Date Range Picker */}
      <div className="flex items-center space-x-2 bg-opacity-60">
        {/* 시작일 */}
        <DatePicker
          placeholder="시작일"
          value={dateFrom}
          onChange={onDateFromChange}
          className="flex-1"
        />
        <div>~</div>
        {/* 종료일 */}
        <DatePicker
          placeholder="종료일"
          value={dateTo}
          onChange={onDateFromTo}
          className="flex-1"
        />
      </div>
      {/* 소속 필터 */}
      <div className="flex items-center border text-xs theme-bg-1 theme-border-1">
        <div
          className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
            customTheme,
          )}`}
        >
          소속
        </div>
        <div className="flex flex-1 items-center justify-around">
          <CheckboxFilter
            filter={selectedTeamTypes}
            setFilter={(checked) => setSelectedTeamTypes(checked)}
          />
        </div>
      </div>
      {/* 지역 필터 */}
      <div className="flex items-center border text-xs theme-bg-1 theme-border-1">
        <div
          className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
            customTheme,
          )}`}
        >
          지역
        </div>
        <div className="flex flex-1 items-center justify-around">
          <CheckboxFilter
            filter={selectedRegions}
            setFilter={(checked) => setSelectedRegions(checked)}
          />
        </div>
      </div>
      <div className="text-xs">
        <AgencyAdminBugoStatusBar feventList={resultValue} />
      </div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{ position: ['bottomCenter'], pageSize: 15 }}
        rowKey={'id'}
        size={'small'}
        onHeaderRow={() => ({
          className: `text-xs font-bold bg-opacity-40 ${mainBgColorGenerator(
            customTheme,
          )}`,
        })}
      />
    </div>
  );
};

export default AgencyAdminBugoListTableMobile;
