import { FC } from 'react';

import { SellerHomePage } from '@service/seller/page/SellerHomePage';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ShopRouter } from './SellerShopRouter';

const SellerAuthRouter: FC = () => {
  const { isStoreSeller, isAdmin } = useAuth();

  const isSellerAuthenticated = isStoreSeller() || isAdmin();

  if (isSellerAuthenticated) {
    sessionStorage.removeItem('redirectUrlAfterSellerLogin');
    return (
      <div className="min-h-screen">
        <Routes>
          {/* <Route
          path="admin/*"
          element={isAdmin() ? <SellerAdminRouter /> : <Navigate to="/flower-seller" />}
        /> */}
          <Route path="home" element={<SellerHomePage />} />
          <Route path="shop/:shopId/*" element={<ShopRouter />} />
          {/* <Route path="mypage" element={<ShopHomePage />} />
        <Route path="delivery-manage" element={<DeliveryManagePage />} /> */}
          <Route path="*" element={<Navigate to="home" />} />
        </Routes>
      </div>
    );
  } else {
    return <Navigate to="/flower-seller/login" />;
  }
};

export default SellerAuthRouter;
