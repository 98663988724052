//* bugo mypage에서 쓰이는 장지 관리 컴포넌트
import React, { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { nonAdminUserUpdate } from '@shared/api/user/user.controller';
import { bugoAgencyWorkerUpdate } from '@shared/api/user/user.interface';
import { Button } from '@shared/components/Button';
import { TextField } from '@shared/components/TextField';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';

const PreferCemeteryEditor: FC = () => {
  const { userProfile, refreshUserProfile } = useAuth();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const cemeteryList = useMemo(
    () => userProfile?.bugoAgencyWorkerDetail?.cemeteryList ?? [],
    [userProfile?.bugoAgencyWorkerDetail?.cemeteryList],
  );

  const [localCemeteryList, setLocalCemeteryList] = useState<string[]>(cemeteryList);

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setLocalCemeteryList((curr) => {
      const newList = [...curr];
      newList[index] = event.target.value;
      return newList;
    });
  };

  const onEdit = async (index: number) => {
    // local list에 존재하지 않거나 수정사항이 존재하지 않으면 return.
    if (
      !userProfile ||
      !localCemeteryList[index] ||
      localCemeteryList[index] === cemeteryList[index]
    )
      return;

    const newList = [...cemeteryList];
    if (!cemeteryList[index]) {
      // 새로 추가한 장지일 경우
      newList.push(localCemeteryList[index] ?? '');
    } else {
      newList[index] = localCemeteryList[index] ?? '';
    }

    // edit
    const updateUser: bugoAgencyWorkerUpdate = {
      _id: userProfile?._id ?? '',
      bugoAgencyWorkerDetail: {
        ...userProfile.bugoAgencyWorkerDetail,
        cemeteryList: newList,
      },
    };

    try {
      await nonAdminUserUpdate(updateUser);
      notificateSuccess(notifiacationInstance, '장지가 성공적으로 수정되었습니다');
      refreshUserProfile();
      setLocalCemeteryList(newList);
    } catch (error) {
      console.error(error);
      notificateError(notifiacationInstance, '장지 수정이 실패했습니다');
    }
  };

  const onDelete = async (index: number) => {
    // DB에 반영되지 않은 장지는 프론트에서 바로 삭제.
    if (!cemeteryList[index])
      return setLocalCemeteryList((curr) => curr.filter((_, i) => i != index));

    if (!userProfile) return;

    const newList = cemeteryList.filter((_, i) => i !== index);

    // edit
    const updateUser: bugoAgencyWorkerUpdate = {
      _id: userProfile?._id ?? '',
      bugoAgencyWorkerDetail: {
        ...userProfile.bugoAgencyWorkerDetail,
        cemeteryList: newList,
      },
    };

    try {
      await nonAdminUserUpdate(updateUser);
      notificateSuccess(notifiacationInstance, '장지가 성공적으로 삭제되었습니다');
      refreshUserProfile();
      setLocalCemeteryList(newList);
    } catch (error) {
      notificateError(notifiacationInstance, '장지 삭제가 실패했습니다');
      console.error(error);
    }
  };

  const onAdd = () => {
    setLocalCemeteryList((curr) => [...curr, '']);
  };

  return (
    <div className="space-y-1">
      {localCemeteryList.map((cemetery, index) => (
        <div
          key={`cemetery-${index}`}
          className="flex items-stretch justify-between gap-1"
        >
          <div className="flex-1 ">
            <TextField
              value={cemetery}
              onChange={(event) => onTextChange(event, index)}
              className="h-10 w-full border px-2 py-1.5 font-medium theme-text-1 theme-bg-1 theme-border-1 placeholder:theme-text-6 focus:theme-border-main"
            />
          </div>

          <div className="flex items-stretch gap-1">
            <Button
              onClick={() => onEdit(index)}
              className="rounded-none border px-2 font-medium shadow-none theme-border-1"
            >
              {cemeteryList[index] ? '수정' : '완료'}
            </Button>
            <Button
              onClick={() => onDelete(index)}
              className="button rounded-none border px-2 font-medium shadow-none theme-border-1"
            >
              {cemeteryList[index] ? '삭제' : '취소'}
            </Button>
          </div>
        </div>
      ))}
      <div className="center-box">
        <Button
          onClick={onAdd}
          className="button-fold border px-8 py-1.5 text-sm font-medium theme-border-1"
        >
          추가
        </Button>
      </div>
    </div>
  );
};

export default PreferCemeteryEditor;
