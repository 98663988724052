import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { feventStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import ClockLoader from '@shared/components/ClockLoader';
import { useParams } from 'react-router-dom';

import { FeventCourtesySender } from '../containers/CourtesySender/CourtesySender';
import { useTitleHook } from '../hooks/useTitleHook';

const FeventCourtesySendPage = function FeventCourtesySendPage() {
  useTitleHook('답례글 전송');
  const { feventId } = useParams();
  const feventState = useRecoilValue(feventStateSelectorFamily(feventId ?? 'undefined'));

  const courtesySender = useMemo(() => {
    if (feventState.status === 'success' && feventState.data) {
      return <FeventCourtesySender fevent={feventState.data} />;
    } else {
      <div className="center-box h-screen-15">
        <ClockLoader />
      </div>;
    }
  }, [feventState.data, feventState.status]);

  return <div className="space-y-6 p-6">{courtesySender}</div>;
};

export default FeventCourtesySendPage;
