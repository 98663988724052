import { BugoData } from '@shared/api/bugo/bugo.interface';
import { formatFeventDateString } from '@shared/utils/formatDate';

const bugoTermMessage = (obituaryTerm: string): string => {
  return (
    (obituaryTerm ? obituaryTerm : '별세') + '하셨기에 아래와 같이 부고를 전해 드립니다.'
  );
};

export const bugoMessageContentString = function (bugo: BugoData) {
  const member = bugo.member;

  const header = member.reverseType ? `${member.fullName} ${member.reverseType}상\n` : '';
  const contentString = '';
  const fevent = bugo.fevent;

  const ret = contentString
    .concat(
      '<訃告>\n',
      header,
      `故 ${fevent.deceasedInfo.name}님께서 ${formatFeventDateString(
        fevent.deceasedInfo.death,
        false,
      )} ${bugoTermMessage(
        fevent.deceasedInfo.bugoTerm ? fevent.deceasedInfo.bugoTerm : '별세',
      )}\n\n`,

      `◆故 ${fevent.deceasedInfo.name}님 부고◆\n`,
      `${`${process.env.REACT_APP_WEB_BASE_URL}/bugo/${bugo._id}`}\n\n`,
      (bugo.mournerText || fevent.mournerText) &&
        (bugo.mournerText ? `${bugo.mournerText}\n\n` : `${fevent.mournerText}\n\n`),

      '황망한 마음에 일일이 연락드리지 못함을 널리 혜량해 주시길 바랍니다.\n\n',
      `${member.relationType.replace(' ', '')} ${member.fullName} 배상`,
    )
    .replace('  ', ' ');

  return ret;
};

export interface BugoKakaoShareContent {
  header: string;
  deathDate: string;
  deceasedName: string;
  bugoTerm: string;
  bugoId: string;
}

export const bugoKakaoShareContent = function (bugo: BugoData) {
  const member = bugo.member;
  const fevent = bugo.fevent;
  return {
    header: member.reverseType ? `${member.fullName} ${member.reverseType}상\n` : '',
    deathDate: formatFeventDateString(fevent.deceasedInfo.death, false),
    deceasedName: fevent.deceasedInfo.name,
    bugoTerm: fevent.deceasedInfo.bugoTerm ? fevent.deceasedInfo.bugoTerm : '별세',
    bugoId: bugo._id,
  };
};
