import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useFeventListByBugoBrandhook } from '@shared/api/fevent/fevent.hook';
import { feventListByBugoBrandStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import { useWorkerUserByBugoBrandHook } from '@shared/api/user/user.hook';
import { workerUserByBugoBrandStateSelector } from '@shared/api/user/user.selector';
import { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import ClockLoader from '@shared/components/ClockLoader';
import { ReloadButton } from '@shared/components/ReloadButton';
import StatPageSideBar from '@shared/components/StatPageSideBar';
import Toggle from '@shared/components/Toggle';
import SearchBar from '@shared/containers/SearchBar/SearchBar';
import { searchTextAtomFamily } from '@shared/containers/SearchBar/state/searchBar.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { RoughRegionEnum } from '@shared/utils/getRoughRegion';
import { useMediaQuery } from 'react-responsive';
import { useParams, useSearchParams } from 'react-router-dom';

import AgencyAdminBugoListTable from '../containers/AgencyAdminBugoListTable/AgencyAdminBugoListTable';
import AgencyAdminBugoListTableMobile from '../containers/AgencyAdminBugoListTable/AgencyAdminBugoListTableMobile';
import { useTitleHook } from '../hooks/useTitleHook';

export enum BugoStatRangeQueryEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

const SEARCH_TEXT_ID = 'agencyAdminBugoStatPage';

const AgencyAdminBugoStatPage: FC = function AgencyAdminBugoStatPage() {
  const { bugoBrandId } = useParams();
  const bugoBrandId_ = bugoBrandId ?? 'undefined';
  useTitleHook('관리자용 페이지', undefined, undefined, true, '/');

  //TODO: 금일 금주 금월 클릭시 해당하는 feventList만 가져오도록 hook을 짜고 그 후에 lazy하게 전체를 가져오도록

  //* bugoBrand로 가져올 수 있는 부고(fevent)를 가져오는 hook
  useFeventListByBugoBrandhook(bugoBrandId_);

  //* bugoBrand에 속해있는 user를 가져오는 hook
  useWorkerUserByBugoBrandHook(bugoBrandId_);

  const workerUserState = useRecoilValue(
    workerUserByBugoBrandStateSelector(bugoBrandId_),
  );

  const feventListState = useRecoilValue(
    feventListByBugoBrandStateSelectorFamily(bugoBrandId_),
  );

  const [isBugoTable, setIsBugoTable] = useState<boolean>(true);

  const [searchParams, _] = useSearchParams();
  const range = searchParams.get('range') as BugoStatRangeQueryEnum | null;

  const searchText = useRecoilValue(searchTextAtomFamily(SEARCH_TEXT_ID));

  //*mobile 기준
  const isMobile = !useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const { userProfile } = useAuth();

  const teamTypesFilter = useMemo(() => {
    const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];
    const teamTypes = bugoBrand?.bugoAgency?.teamTypes ?? [];
    const result: CheckBoxFilter = {};
    teamTypes.forEach((teamType) => (result[teamType] = true));

    return result;
  }, [userProfile?.bugoAgencyAdminDetail?.bugoBrands]);

  const regionsFilter = useMemo(() => {
    const roughRegions = Object.keys(RoughRegionEnum);
    const result: CheckBoxFilter = {};
    roughRegions.forEach((region) => (result[region] = true));

    return result;
  }, []);

  const table = useMemo(() => {
    if (
      workerUserState.status === 'success' &&
      workerUserState.data &&
      feventListState.status === 'success' &&
      feventListState.data
    ) {
      return (
        <AgencyAdminBugoListTable
          workerUserList={workerUserState.data}
          feventList={feventListState.data}
          isBugoTable={isBugoTable}
          range={range}
          teamTypesFilter={teamTypesFilter}
          regionsFilter={regionsFilter}
        />
      );
    } else {
      return (
        <div className="center-box h-screen-15">
          <ClockLoader width="4rem" />
        </div>
      );
    }
  }, [
    workerUserState,
    feventListState,
    isBugoTable,
    range,
    teamTypesFilter,
    regionsFilter,
  ]);

  const onToggle = (value: boolean) => setIsBugoTable(value);

  if (isMobile) {
    return (
      <div>
        {/* Search Bar */}
        <div className="p-4">
          <SearchBar
            searchTextAtomId={SEARCH_TEXT_ID}
            placeholder="소속/지도사성함/지역/회원번호로 검색"
          />
        </div>
        {feventListState.status === 'success' && feventListState.data ? (
          <AgencyAdminBugoListTableMobile
            feventList={feventListState.data}
            teamTypesFilter={teamTypesFilter}
            range={range}
            searchValue={searchText}
            regionsFilter={regionsFilter}
          />
        ) : (
          <div className="center-box h-screen-15">
            <ClockLoader />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex">
        {/* Sidebar */}
        <div className="flex flex-col">
          <StatPageSideBar />
        </div>
        <div className="mx-auto max-w-screen-2xl flex-1">
          <div className="py-8 px-10">
            <div className="flex justify-between space-x-10">
              <div>
                <p className="text-20 font-bold">부고 등록 내역 조회</p>
                <p className="font-sm pt-2 text-gray-500">
                  조건을 설정하여 조회할 수 있습니다.(최대 15개월 이내의 데이터 조회 가능)
                </p>
              </div>
              <div className="center-box h-full gap-4">
                <ReloadButton />
                <Toggle
                  toggle={isBugoTable}
                  onChagne={onToggle}
                  toggleValue={{
                    on: '부고 내역',
                    off: '지도사 순위',
                  }}
                />
              </div>
            </div>
            <div className="pt-4">{table}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default AgencyAdminBugoStatPage;
