import { Gender } from '@shared/types';

type RelationReverse = {
  relation: string;
  reverseRelation: string;
};

const RelationReverseMap: RelationReverse[] = [
  { relation: '부군', reverseRelation: '부인' },
  { relation: '미망인', reverseRelation: '부군' },
  { relation: '형제', reverseRelation: '형제' },
  { relation: '자매', reverseRelation: '형제' },
  { relation: '자', reverseRelation: '' },
  { relation: '아들', reverseRelation: '' },
  { relation: '딸', reverseRelation: '' },
  { relation: '며느리', reverseRelation: '시' },
  { relation: '자부', reverseRelation: '시' },
  { relation: '녀', reverseRelation: '' },
  { relation: '사위', reverseRelation: '장' },
  { relation: '손', reverseRelation: '조' },
  { relation: '손자', reverseRelation: '조' },
  { relation: '손녀', reverseRelation: '조' },
  { relation: '외손', reverseRelation: '외조' },
  { relation: '외손자', reverseRelation: '외조' },
  { relation: '외손녀', reverseRelation: '외조' },
];

export const getReverseType = (relationType: string, deceasedSex: Gender) => {
  const relationType_ = relationType.replace(' ', '');

  const map = RelationReverseMap.find((item) => {
    return item.relation === relationType_;
  });
  if (map) {
    const reverseModifier = map.reverseRelation;
    if (reverseModifier.length === 1) {
      if (reverseModifier === '장') {
        const reverseSex = deceasedSex === Gender.Male ? '인' : '모';
        return reverseModifier + reverseSex;
      } else {
        const reverseSex = deceasedSex === Gender.Male ? '부' : '모';
        return reverseModifier + reverseSex;
      }
    } else if (reverseModifier.length === 0) {
      const reverseSex = deceasedSex === Gender.Male ? '부친' : '모친';
      return reverseSex;
    } else if (
      reverseModifier === '형제' ||
      reverseModifier === '부인' ||
      reverseModifier === '부군'
    ) {
      return reverseModifier;
    } else if (reverseModifier.length === 2) {
      const reveseSex = deceasedSex === Gender.Male ? '부' : '모';
      return reverseModifier + reveseSex;
    } else return '';
  } else {
    return '';
  }
};
