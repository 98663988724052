import { FC } from 'react';

import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { Button } from '@shared/components/Button';
import { TextField } from '@shared/components/TextField';
import { Coord } from '@shared/interfaces/map.interface';
import { SubmitHandler, useForm } from 'react-hook-form';

import AddressSearcher from '../AddressSearcher/AddressSearcher';

enum FuneralHomeInfoFormEnum {
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  Address = 'address',
  ParkingAddress = 'parkingAddress',
  Coord = 'coord',
}

export interface IFuneralHomeInfoForm {
  [FuneralHomeInfoFormEnum.Name]: string;
  [FuneralHomeInfoFormEnum.PhoneNumber]: string;
  [FuneralHomeInfoFormEnum.Address]: string;
  [FuneralHomeInfoFormEnum.ParkingAddress]: string;
  [FuneralHomeInfoFormEnum.Coord]: { x: string; y: string };
}

interface IProps {
  onSubmit: SubmitHandler<IFuneralHomeInfoForm>;
  funeralHomeInfo?: FuneralHomeInfo;
}

const FuneralHomeInfoSearchForm: FC<IProps> = ({ onSubmit, funeralHomeInfo }: IProps) => {
  const { register, handleSubmit, setValue } = useForm<IFuneralHomeInfoForm>({
    defaultValues: {
      name: funeralHomeInfo?.name,
      phoneNumber: funeralHomeInfo?.phoneNumber,
      address: funeralHomeInfo?.address,
      parkingAddress: funeralHomeInfo?.parkingAddress,
    },
  });

  const onAddressChange = (addressItem: { address: string; coord?: Coord }) => {
    setValue(FuneralHomeInfoFormEnum.Address, addressItem.address);
    if (addressItem.coord) setValue(FuneralHomeInfoFormEnum.Coord, addressItem.coord);
  };

  return (
    <div className="space-y-3 text-sm">
      {/* Name */}
      <div className="space-y-2">
        <h5 className="font-medium">장례식장 이름</h5>
        <TextField
          type="text"
          autoComplete="off"
          className="w-full"
          placeholder="장례식장 이름"
          {...register(FuneralHomeInfoFormEnum.Name, {
            required: true,
          })}
        ></TextField>
      </div>
      {/* PhoneNumber */}
      <div className="space-y-2">
        <h5 className="font-medium">전화번호</h5>
        <TextField
          type="text"
          autoComplete="off"
          className="w-full"
          placeholder="전화번호"
          {...register(FuneralHomeInfoFormEnum.PhoneNumber)}
        ></TextField>
      </div>
      {/* Address */}
      <div className="space-y-2">
        <h5 className="font-medium">주소검색</h5>
        <AddressSearcher onChange={onAddressChange} />
      </div>
      {/* parkingAddress */}
      <div className="space-y-2">
        <h5 className="font-medium">주차안내</h5>
        <TextField
          type="text"
          autoComplete="off"
          className="w-full"
          placeholder="주차안내"
          {...register(FuneralHomeInfoFormEnum.ParkingAddress)}
        ></TextField>
      </div>
      {/* Confirm Button */}
      <div>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          className="button-rectangle mt-8 text-white theme-bg-main"
        >
          다음
        </Button>
      </div>
    </div>
  );
};

export default FuneralHomeInfoSearchForm;
