import React, { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import DateFilterSearcher from '@service/bugo/components/DateFilterSearcher';
import { BugoStatRangeQueryEnum } from '@service/bugo/page/AgencyAdminBugoStatMobilePage';
import { FeventByBugoBrand } from '@shared/api/fevent/fevent.interface';
import { User } from '@shared/api/user/user.interface';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { TextField } from '@shared/components/TextField';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { getDefaultDateFrom } from '@shared/utils/dateUtils';
import { filterPaidShopOrder } from '@shared/utils/filterPaidShopOrder';
import { formatDate } from '@shared/utils/formatDate';
import { getRoughRegion } from '@shared/utils/getRoughRegion';
import { mainBgColorGenerator } from '@shared/utils/mainColorGenerator';
import { Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import BugoDetailModalOpener from '../BugoDetailModalOpener/BugoDetailModalOpener';
import WorkerDetailModalOpener from '../WorkerDetailModalOpener/WorkerDetailModalOpener';
import { AgencyAdminBugoStatusBar } from './AgencyAdminBugoStatusBar';

interface IProps {
  feventList: FeventByBugoBrand[];
  range: BugoStatRangeQueryEnum | null;
  teamTypesFilter: CheckBoxFilter;
  regionsFilter: CheckBoxFilter;
}

type FeventTableData = {
  id: string;
  index: number;
  user: User;
  teamName: string;
  region: string;
  funeralHome: string;
  createdAt: Date | '';
  orderListLen: number;
  isDeleted: boolean;
};

const onDateRangeChange = (
  dates: [any, any] | null,
  setValue: React.Dispatch<React.SetStateAction<[any, any]>>,
) => {
  if (dates) {
    setValue(dates);
  } else {
    setValue([null, null]);
  }
};

const AgencyAdminFeventTable: FC<IProps> = ({
  feventList,
  range,
  teamTypesFilter,
  regionsFilter,
}: IProps) => {
  const customTheme = useRecoilValue(customThemeAtom);
  const [searchValue, setSearchValue] = useState<string>('');

  const [dateRange, setDateRange] = useState<[any | null, any | null]>([null, null]);

  const [dateFilteredFeventList, setDateFilteredFeventList] = useState<
    FeventByBugoBrand[]
  >(feventList ?? []);

  // TeamType Filtering
  const [selectedTeamTypes, setSelectedTeamTypes] = useState(teamTypesFilter);

  const teamTypesFilteredFeventList = useMemo(() => {
    return dateFilteredFeventList.filter(
      (fevent) => selectedTeamTypes[fevent.user.bugoAgencyWorkerDetail?.teamType ?? ''],
    );
  }, [dateFilteredFeventList, selectedTeamTypes]);

  // Region Filtering
  const [selectedRegions, setSelectedRegions] = useState(regionsFilter);

  const regionsFilteredFeventList = useMemo(() => {
    return teamTypesFilteredFeventList.filter(
      (fevent) =>
        selectedRegions[
          getRoughRegion(fevent.funeralHomeInfo?.address?.split(' ')[0] ?? '')
        ],
    );
  }, [teamTypesFilteredFeventList, selectedRegions]);

  // Search
  const resultValue = useMemo(() => {
    const searcher = new FuzzySearch(regionsFilteredFeventList, [
      'user.info.name',
      'funeralHomeInfo.name',
      'funeralHomeInfo.address',
      'registerNumber',
      'user.bugoAgencyWorkerDetail.teamType',
      'user.bugoAgencyWorkerDetail.teamName',
    ]);
    return searcher.search(searchValue);
  }, [regionsFilteredFeventList, searchValue]);

  const tableData: FeventTableData[] = useMemo(() => {
    return _.map(resultValue as FeventByBugoBrand[] | null, (fevent, index) => {
      return {
        id: fevent._id,
        index: index,
        user: fevent.user,
        teamName: getAgencyWorkerTeamName(fevent.user),
        region: fevent.funeralHomeInfo.address.split(' ')[0] ?? '',
        funeralHome: fevent.funeralHomeInfo.name ?? fevent.funeralHomeInfoEmbed?.name,
        createdAt: fevent.createdAt,
        orderListLen: filterPaidShopOrder(fevent.shopOrders).length,
        isDeleted: fevent.meta.isDeleted,
      };
    });
  }, [resultValue]);

  const csvTableData = tableData.map((item) => {
    return {
      소속: item.teamName,
      소속지역: item.user.bugoAgencyWorkerDetail?.region,
      지도사: item.user.info.name,
      장례식장: item.funeralHome,
      등록일: item.createdAt
        ? formatDate(item.createdAt, {
            contains: {
              year: false,
              month: true,
              date: true,
              day: true,
              hours: false,
              minutes: false,
            },
          })
        : '',
      등록시간: item.createdAt
        ? formatDate(item.createdAt, {
            contains: {
              year: false,
              month: false,
              date: false,
              day: false,
              hours: true,
              minutes: true,
            },
          })
        : '',
      화한판매수량: item.orderListLen,
      개인정보파기여부: item.isDeleted ? '파기완료' : '파기예정',
    };
  });

  const columns: TableColumnsType<FeventTableData> = [
    {
      width: '5%',
      title: <div className="break-keep">index</div>,
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (index: number) => {
        return <p className="break-keep text-xs">{index + 1}</p>;
      },
    },
    {
      title: '소속',
      dataIndex: 'teamName',
      key: 'teamName',
      align: 'center',
      render: (teamName) => {
        return <p className="font-medium">{teamName}</p>;
      },
    },
    {
      title: '소속지역',
      dataIndex: 'user',
      key: 'userRegion',
      align: 'center',
      render: (user: User) => {
        return <p className="font-medium">{user.bugoAgencyWorkerDetail?.region}</p>;
      },
    },
    {
      title: '지도사(부고발송)',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      render: (user) => {
        return (
          <WorkerDetailModalOpener
            workerName={user.info.name}
            workerId={user._id}
            fontSize={14}
          />
        );
      },
    },
    {
      title: '장례식장',
      dataIndex: 'funeralHome',
      key: 'funeralHome',
      align: 'center',
      render: (funeralHome) => {
        return <p className="font-medium">{funeralHome}</p>;
      },
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt-Date',
      align: 'center',
      render: (value: Date | '') => {
        return (
          <p>
            {value
              ? formatDate(value, {
                  contains: {
                    year: false,
                    month: true,
                    date: true,
                    day: true,
                    hours: false,
                    minutes: false,
                  },
                })
              : ''}
          </p>
        );
      },
    },
    {
      title: '등록시간',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (value: Date | '') => {
        return (
          <div>
            <p>
              {value
                ? formatDate(value, {
                    contains: {
                      year: false,
                      month: false,
                      date: false,
                      day: false,
                      hours: true,
                      minutes: true,
                    },
                  })
                : ''}
            </p>
          </div>
        );
      },
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => {
          if (!a.createdAt || !b.createdAt) return 0;
          return a.createdAt.getTime() - b.createdAt.getTime();
        },
      },
    },
    {
      title: '화환판매수량',
      dataIndex: 'orderListLen',
      key: 'orderListLen',
      align: 'center',
      render: (value: number) => {
        // console.log(value);
        return <p className="text-gray-600">{value.toLocaleString()}개</p>;
      },
    },
    {
      title: '정보파기',
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      align: 'center',
      render: (value: boolean) => {
        return <p className="text-gray-600">{value ? '완료' : '예정'}</p>;
      },
    },
    {
      title: '부고상세',
      key: 'bugo modal button',
      align: 'center',
      render: (value: FeventTableData) => {
        return (
          <div className="center-box">
            <BugoDetailModalOpener
              fevent={feventList.find((fevent) => fevent._id === value.id) ?? null}
            />
          </div>
        );
      },
    },
  ];

  // default range 적용
  useEffect(() => {
    if (!range) return;

    const from = getDefaultDateFrom(range);
    const to = new Date();

    setDateRange([dayjs(from), dayjs(to)]);
  }, [range]);

  useEffect(() => {
    if (!feventList) return;

    // dateRange 적용.
    const rangeFilteredList = feventList.filter((fevent) => {
      if (!dateRange[0] || !dateRange[1]) return true;

      if (!fevent.createdAt) return false;

      const createdAt = new Date(fevent.createdAt);
      if (dateRange[0].toDate() <= createdAt && dateRange[1].toDate() >= createdAt) {
        return true;
      } else {
        return false;
      }
    });

    setDateFilteredFeventList(rangeFilteredList);
  }, [dateRange, feventList]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  return (
    <div className="rounded-lg bg-white px-4">
      <div className="flex w-full items-center justify-between space-x-10 py-4">
        <div className="grid flex-1 grid-cols-2 gap-x-8 gap-y-4">
          <div className="flex items-center justify-start gap-2">
            <div className="flex-1 text-sm">
              <AgencyAdminBugoStatusBar feventList={resultValue} />
            </div>
            <div className="flex-1">
              <TextField
                className="w-full border-gray-500"
                type={'search'}
                onChange={onChangeHandler}
                placeholder="검색 상조 지도사명"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <DateFilterSearcher
              dateRange={dateRange}
              onChange={(dates) => onDateRangeChange(dates, setDateRange)}
              size="large"
            />
            <CSVLink
              filename={'Expense_Table.csv'}
              data={_.flatMapDeep([csvTableData])}
              className="center-box filled-gray-800 rounded-md px-4 py-2 font-bold"
            >
              <p className="text-white">엑셀 다운로드</p>
            </CSVLink>
          </div>
          {/* 소속 필터 */}
          <div className="flex items-center space-x-4 border text-sm theme-bg-1 theme-border-1">
            <div
              className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
                customTheme,
              )}`}
            >
              소속
            </div>
            <div className="flex flex-1 items-center justify-around space-x-4 py-1 pr-2">
              <CheckboxFilter
                filter={selectedTeamTypes}
                setFilter={(checked) => setSelectedTeamTypes(checked)}
              />
            </div>
          </div>
          {/* 지역 필터 */}
          <div className="flex items-center space-x-4 border text-sm theme-bg-1 theme-border-1">
            <div
              className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
                customTheme,
              )}`}
            >
              지역
            </div>
            <div className="flex flex-1 items-center justify-around space-x-4 py-1 pr-2">
              <CheckboxFilter
                filter={selectedRegions}
                setFilter={(checked) => setSelectedRegions(checked)}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 15,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
    </div>
  );
};

export default AgencyAdminFeventTable;
