import { useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole, UserRole } from '@shared/types';

import { fetcher, queryString } from './../../hooks/recoil-query';
import {
  workerUserDataAtomFamily,
  workerUserListByBugoBrandDataAtomFamily,
  workerUserListByBugoBrandStatusAtomFamily,
  workerUserStatusAtomFamily,
} from './user.atom';

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook Url
export const workerUserByBugoBrandHookUrl = (
  bugoRole: LowerRole,
  bugoBrandId: string,
) => {
  return `/${bugoRole}/user?${queryString({
    filter: {
      bugoBrand: bugoBrandId,
      roles: UserRole.BugoAgencyWorker,
    },
    populate: [
      {
        path: 'bugoAgencyWorkerDetail.fevents',
        populate: [
          {
            path: 'shopOrders',
          },
          {
            path: 'funeralHomeInfo',
          },
        ],
      },
    ],
  })}`;
};

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook
export const useWorkerUserByBugoBrandHook = (bugoBrandId: string) => {
  const { bugoRole, userProfile } = useAuth();

  useRecoilQuery(
    workerUserListByBugoBrandStatusAtomFamily(bugoBrandId),
    workerUserListByBugoBrandDataAtomFamily(bugoBrandId),
    workerUserByBugoBrandHookUrl(bugoRole(), bugoBrandId),
    fetcher,
    !(
      userProfile?.roles.includes(UserRole.BugoAgencyAdmin) ||
      userProfile?.roles.includes(UserRole.Admin)
    ),
  );
};

//* userId로 지도사을 불러오는 url
export const workerUserByIdHookUrl = (bugoRole: LowerRole, userId: string) => {
  return `/${bugoRole}/user/${userId}?${queryString({
    filter: {
      roles: UserRole.BugoAgencyWorker,
    },
    populate: [
      {
        path: 'bugoAgencyWorkerDetail.fevents',
        options: {
          sort: { createdAt: -1 },
        },
        populate: [
          {
            path: 'shopOrders',
          },
          {
            path: 'funeralHomeInfo',
          },
        ],
      },
    ],
  })}`;
};

//* userId로 지도사을 불러오는 hook
export const useWorkerUserByIdHook = (userId: string) => {
  const { bugoRole, userProfile } = useAuth();

  useRecoilQuery(
    workerUserStatusAtomFamily(userId),
    workerUserDataAtomFamily(userId),
    workerUserByIdHookUrl(bugoRole(), userId),
    fetcher,
    !(
      userProfile?.roles.includes(UserRole.BugoAgencyAdmin) ||
      userProfile?.roles.includes(UserRole.Admin)
    ),
  );
};
