import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { updateShop } from '@shared/api/shop/shop.controller';
import { sellerShopHookUrl } from '@shared/api/shop/shop.hook';
import { ShopData, ShopUpdate } from '@shared/api/shop/shop.interface';
import { Button } from '@shared/components/Button';
import TelField from '@shared/components/TelField';
import { TextField } from '@shared/components/TextField';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import {
  ISellerShopInfoForm,
  SellerShopInfoFormEnum,
} from './sellerShopInfoForm.interface';

interface IProps {
  shop: ShopData;
}

const SellerShopInfoForm: FC<IProps> = ({ shop }: IProps) => {
  const { storeRole } = useAuth();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const queryClient = useQueryClient();

  const { register, handleSubmit, control } = useForm<ISellerShopInfoForm>({
    defaultValues: {
      name: shop.name ?? '',
      phoneNumber: shop.phoneNumber ?? '',
      businessNumber: shop.businessNumber ?? '',
      address: shop.address ?? '',
      addressDetail: shop.addressDetail ?? '',
      postalCode: shop.postalCode ?? '',
    },
  });

  const phoneNumber = useWatch({ control, name: SellerShopInfoFormEnum.PhoneNumber });

  const onSubmit: SubmitHandler<ISellerShopInfoForm> = async (data) => {
    const shopUpdate: ShopUpdate = {
      _id: shop._id,
      ...data,
    };

    try {
      await updateShop(shopUpdate, storeRole());
      notificateSuccess(notifiacationInstance, '판매자 정보가 성공적으로 수정되었습니다');
      queryClient.invalidateQueries(sellerShopHookUrl(shop._id, storeRole()));
    } catch (error) {
      console.error(error);
      notificateError(notifiacationInstance, '판매자 정보 수정에 실패했습니다');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-1">
      <TextField
        label="상호"
        labelClassname="font-bold p-2 text-sm"
        className="w-full"
        placeholder="상호"
        {...register(SellerShopInfoFormEnum.Name, { required: true })}
      />
      <TelField
        label="대표전화번호"
        labelClassname="font-bold p-2 text-sm"
        placeholder="대표전화번호"
        value={phoneNumber}
        register={register}
        name={SellerShopInfoFormEnum.PhoneNumber}
        className="w-full"
      />
      <TextField
        label="사업자번호"
        labelClassname="font-bold p-2 text-sm"
        className="w-full"
        placeholder="사업자번호"
        {...register(SellerShopInfoFormEnum.BusinessNumber, { required: true })}
      />
      <TextField
        label="주소"
        labelClassname="font-bold p-2 text-sm"
        className="w-full"
        placeholder="주소"
        {...register(SellerShopInfoFormEnum.Address, { required: true })}
      />
      <TextField
        label="상세주소"
        labelClassname="font-bold p-2 text-sm"
        className="w-full"
        placeholder="상세주소"
        {...register(SellerShopInfoFormEnum.AddressDetail)}
      />
      <TextField
        label="우편번호"
        labelClassname="font-bold p-2 text-sm"
        className="w-full"
        placeholder="우편번호"
        {...register(SellerShopInfoFormEnum.PostalCode, { required: true })}
      />
      <div className="pt-4">
        <Button
          type="submit"
          text="수정"
          className="button-rectangle text-white theme-bg-main"
        />
      </div>
    </form>
  );
};

export default SellerShopInfoForm;
