//* 관리자용 장례식장 정보 관리 페이지
import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { FuneralHomeInfoListStateSelector } from '@shared/api/funeralHomeInfo/funeralHomeInfo.selector';
import ClockLoader from '@shared/components/ClockLoader';
import { TextField } from '@shared/components/TextField';
import FuneralHomeInfoSearchList from '@shared/containers/FuneralHomeInfoSearcher/FuneralHomeInfoSearchList';
import FuzzySearch from 'fuzzy-search';
import { useNavigate } from 'react-router-dom';

import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

const AdminFuneralHomeInfoManagePage: FC = function AdminFuneralHomeInfoManagePage() {
  useAdminTitleHook('장례식장정보 관리');
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const funeralHomeInfoListState = useRecoilValue(FuneralHomeInfoListStateSelector);
  const navigate = useNavigate();
  const funeralHomeInfoListTable = useMemo(() => {
    if (funeralHomeInfoListState.status === 'success' && funeralHomeInfoListState.data) {
      const funeralHomeInfoList = funeralHomeInfoListState.data;

      const searcher = new FuzzySearch(funeralHomeInfoList, [
        'name',
        'address',
        'phoneNumber',
      ]);

      const searchedFuneralHomeInfoList = searcher.search(searchValue);
      const onItemClickInListStep = (item: FuneralHomeInfo<undefined, string>) => {
        navigate(`/admin/funeral-home-info/${item._id}/edit`);
      };
      return (
        <FuneralHomeInfoSearchList
          searchedFuneralHomeInfoList={searchedFuneralHomeInfoList}
          onItemClick={onItemClickInListStep}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          chunkSize={10}
        />
      );
    } else {
      return (
        <div className="center-box">
          <ClockLoader />
        </div>
      );
    }
  }, [
    funeralHomeInfoListState.data,
    funeralHomeInfoListState.status,
    navigate,
    searchValue,
    selectedPage,
  ]);

  return (
    <div className="space-y-2 p-2">
      <TextField
        className="w-full"
        placeholder="장례식장 이름 및 지역을 검색하세요."
        onChange={(e) => {
          setSelectedPage(1);
          setSearchValue(e.target.value);
        }}
      />
      <div className="space-y-2 bg-gray-100">{funeralHomeInfoListTable}</div>
    </div>
  );
};

export default AdminFuneralHomeInfoManagePage;
