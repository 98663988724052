//* 부고 관리자용 공지사항 관리 페이지
import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import AnnouncementCard from '@service/bugo/components/AnnouncementCard';
import { useBugoBrandAnnouncementListhook } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.hook';
import { BugoBrandAnnouncementStatus } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.interface';
import { bugoBrandAnnouncementListStateSelector } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.selector';
import { Button } from '@shared/components/Button';
import ClockLoader from '@shared/components/ClockLoader';
import { Icon } from '@shared/components/icons';
import Tabs from '@shared/components/Tabs';
import SearchBar from '@shared/containers/SearchBar/SearchBar';
import { searchTextAtomFamily } from '@shared/containers/SearchBar/state/searchBar.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import FuzzySearch from 'fuzzy-search';
import { Link } from 'react-router-dom';

import { useTitleHook } from '../hooks/useTitleHook';

const SEARCH_TEXT_ID = 'bugoBrandAnnouncementManagePage';
const DEFAULT_VISIBLE_NUM = 5;

const BugoBrandAnnouncementManagePage: FC = () => {
  useTitleHook('공지사항 관리');

  useBugoBrandAnnouncementListhook();
  const { userProfile } = useAuth();
  const { PlusSolid } = Icon();

  const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];
  const teamTypeList = ['*'].concat(bugoBrand?.bugoAgency?.teamTypes ?? []);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const searchText = useRecoilValue(searchTextAtomFamily(SEARCH_TEXT_ID));

  const announcementListState = useRecoilValue(bugoBrandAnnouncementListStateSelector);

  const tabItems = useMemo(() => {
    return teamTypeList.map((teamType) => (teamType === '*' ? '전체' : teamType));
  }, [teamTypeList]);

  const [viewAllList, setViewAllList] = useState<boolean>(false);
  const [listLen, setListLen] = useState<number>(0);

  const selectedTeamType = useMemo(
    () => teamTypeList[selectedTab] ?? '',
    [selectedTab, teamTypeList],
  );

  const announcementList = useMemo(() => {
    if (announcementListState.status === 'success' && announcementListState.data) {
      const searcher = new FuzzySearch(announcementListState.data, ['title']);
      const searchedAnnouncementList = searcher.search(searchText);

      const visibleList = searchedAnnouncementList.filter(
        (announcement) => announcement.status === BugoBrandAnnouncementStatus.Visible,
      );

      const selectedList = visibleList.filter((item) => {
        if (selectedTeamType === '*') {
          return true;
        } else {
          return item.teamTypes.indexOf(selectedTeamType) !== -1;
        }
      });

      const announcementList = viewAllList
        ? selectedList
        : selectedList.slice(0, DEFAULT_VISIBLE_NUM);
      setListLen(selectedList.length);

      return announcementList.map((announcement) => (
        <AnnouncementCard
          key={'announcement-card-' + announcement._id}
          announcement={announcement}
        />
      ));
    } else {
      <div className="center-box min-h-screen-15">
        <ClockLoader />
      </div>;
    }
  }, [announcementListState, searchText, selectedTeamType, viewAllList]);

  return (
    <div className="space-y-4">
      <Tabs
        items={tabItems}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      ></Tabs>
      <div className="space-y-3 px-4">
        <SearchBar
          searchTextAtomId={SEARCH_TEXT_ID}
          placeholder="공지 제목으로 검색하세요."
        />
        <div className="flex items-center justify-end">
          <Link
            to="bugo-brand/announcement/add"
            state={{
              selectedTeamType,
            }}
          >
            <div className="button cursor-pointer px-2 py-1 theme-bg-1">
              <PlusSolid className="wh-4 mr-1" />
              공지사항생성
            </div>
          </Link>
        </div>
        {announcementList}
        <div className="flex justify-center">
          {listLen > DEFAULT_VISIBLE_NUM && (
            <Button
              onClick={() => setViewAllList((curr) => !curr)}
              className="button-fold"
            >
              <p>{viewAllList ? '접기' : '전체보기'}</p>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BugoBrandAnnouncementManagePage;
