//* 상주만 받는 페이지

import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { bugoByMemberIdStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import {
  useCourtesyAssetListByMemberHook,
  useCourtesyContentListByMemberHook,
} from '@shared/api/courtesyAsset/courtesyAsset.hook';
import { CourtesyContent } from '@shared/api/courtesyAsset/courtesyAsset.interface';
import ClockLoader from '@shared/components/ClockLoader';
import { notificateError } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { Location, useParams } from 'react-router-dom';

import { MemberCourtesySender } from '../containers/MemberCourtesy/MemberCourtesySender';
import { useTitleHook } from '../hooks/useTitleHook';

export interface ILocationState extends Location {
  state: {
    tab?: number;
    courtesyContent?: CourtesyContent;
  } | null;
}

const CourtesySenderPage: FC = () => {
  useTitleHook('답례인사', ' ', undefined, true, undefined, false);
  const { memberId } = useParams<{ memberId?: string }>();
  const memberId_ = memberId ?? 'undefined';
  useCourtesyAssetListByMemberHook(`${memberId}`);
  useCourtesyContentListByMemberHook(`${memberId}`);

  const bugoByMemberIdState = useRecoilValue(
    bugoByMemberIdStateSelectorFamily(memberId_),
  );

  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  // memberId가 없거나 잘못된 형식일 경우 404.
  useEffect(() => {
    if (bugoByMemberIdState.status === 'error') {
      notificateError(notifiacationInstance, '해당 부고를 찾을 수 없습니다.');
    }
  }, [bugoByMemberIdState.status, notifiacationInstance]);

  const mainContent = useMemo(() => {
    if (bugoByMemberIdState.status === 'success' && bugoByMemberIdState.data) {
      return <MemberCourtesySender bugo={bugoByMemberIdState.data} />;
    } else {
      return <ClockLoader />;
    }
  }, [bugoByMemberIdState.data, bugoByMemberIdState.status]);

  return <div>{mainContent}</div>;
};

export default CourtesySenderPage;
