//* 부고전송페이지
import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { FeventFormUseMode } from '@service/bugo/utils/feventAddEdit.util';
import { createBugo, sendBugo, updateBugo } from '@shared/api/bugo/bugo.controller';
import { feventHookUrl } from '@shared/api/fevent/fevent.hook';
import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { updateMember } from '@shared/api/member/member.controller';
import { BottomPopup } from '@shared/components/BottomPopup';
import { Button } from '@shared/components/Button';
import { LoadingModal } from '@shared/components/LoadingModal';
import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { UserRole } from '@shared/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import {
  MemberBugoIFormInput,
  MemberBugoIFormInputs,
  MemberBugoSendFormInputsEnum,
} from '../../interfaces/memberBugoSend.interface';
import { MemberBugoSender } from '../MemberBugoSend/MemberBugoSender';

interface Props {
  fevent: FeventOne;
  useMode?: FeventFormUseMode;
}

const FeventSendBugoForm: FC<Props> = function FeventSendBugoForm({
  fevent,
  useMode,
}: Props) {
  const { bugoRole } = useAuth();
  //*useForm 선언
  const { register, handleSubmit, setValue, control } = useForm<MemberBugoIFormInputs>();
  const [, setSearchParams] = useSearchParams();

  const { userProfile } = useAuth();
  const memberOrderList = fevent.memberOrderList;
  const navigate = useNavigate();
  const memberHasPhoneNumberList = memberOrderList?.filter(
    (member) => member?.phoneNumber,
  );

  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const queryClient = useQueryClient();
  const { isLoading: sendingBugo, mutateAsync: mutateSendAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        if (fevent) {
          notificateSuccess(notifiacationInstance, '부고안내 전송 완료');
          queryClient.invalidateQueries(feventHookUrl(fevent._id, bugoRole()));
        }
      },
    },
  );

  // _id 세팅
  useEffect(() => {
    memberHasPhoneNumberList.map((member, index) => {
      setValue(`memberBugo.${index}._id`, member._id);
      setValue(`memberBugo.${index}.bugo`, member.bugo);
      setValue(`memberBugo.${index}.bugoId`, member.bugo?._id);
      setValue(`memberBugo.${index}.mournerText`, member.bugo?.mournerText);
      setValue(`memberBugo.${index}.memberId`, member._id);
      setValue(`memberBugo.${index}.phoneNumber`, member.phoneNumber);
    });
  }, [memberHasPhoneNumberList, setValue]);

  const getBugoIdListAfterUpdateBugo = async (
    memberBugoIFormInputs: MemberBugoIFormInput[],
    sendMode?: boolean,
  ) => {
    /*
    * member 별로
    0. bugo가 있는지 확인
    1. bugo가 없다면 bugo 생성
    2. bugo가 있다면 update 여부 확인 후 bugo 업데이트
     */
    if (userProfile) {
      const bugoIdList = await Promise.all(
        memberBugoIFormInputs.map(async (memberBugoInput) => {
          if (memberBugoInput.memberId) {
            const memberUpdate = {
              _id: memberBugoInput.memberId,
              phoneNumber: memberBugoInput.phoneNumber,
            };
            await updateMember(memberUpdate, bugoRole());
          }

          let bugoId = memberBugoInput.bugoId;
          if (memberBugoInput.bugoId) {
            const bugoUpdate = {
              _id: memberBugoInput.bugoId,
              mournerText:
                memberBugoInput[MemberBugoSendFormInputsEnum.MournerText] ??
                fevent.mournerText,
            };
            const bugo = await updateBugo(bugoUpdate, bugoRole());

            if (sendMode && bugo.lastSentPhoneNumber === memberBugoInput.phoneNumber) {
              return null;
            }
          } else {
            const bugoCreate = {
              user: userProfile._id,
              fevent: fevent._id,
              bugoBrand: userProfile.roles.includes(UserRole.BugoAgencyAdmin)
                ? userProfile.bugoAgencyAdminDetail?.bugoBrands[0]?._id
                : userProfile.bugoBrand,
              member: memberBugoInput._id,
              mournerText:
                memberBugoInput[MemberBugoSendFormInputsEnum.MournerText] ??
                fevent.mournerText,
              schedule: {
                courtesySend: false,
              },
              config: {
                isEnable: true,
              },
            };
            const bugo = await createBugo(bugoCreate, bugoRole());
            bugoId = bugo._id;
            // 마지막에 안보낸것만 보내도록
            if (sendMode && bugo.lastSentPhoneNumber === memberBugoInput.phoneNumber) {
              return null;
            }
          }
          return bugoId;
        }),
      );
      return bugoIdList;
    }
  };

  //* 전체 부고 발송 함수
  const sendBugoFunc = async (memberBugoIFormInputs: MemberBugoIFormInput[]) => {
    const bugoIdList = await getBugoIdListAfterUpdateBugo(memberBugoIFormInputs, true);
    if (bugoIdList) {
      const bugoIds = bugoIdList.filter((item) => {
        if (item) {
          return true;
        } else return false;
      }) as string[];
      const result = await sendBugo(bugoIds, bugoRole());
      console.log('message', result);
    }
  };

  const onSubmit: SubmitHandler<MemberBugoIFormInputs> = async (data) => {
    await mutateSendAsync(sendBugoFunc(data.memberBugo));
  };

  const previewOpenId = `sendBugo-preview-${fevent._id}`;
  const setPreviewModalOpen = useSetRecoilState(
    simpleOpenAtomFamily(`sendBugo-preview-${fevent._id}`),
  );

  //* 미리보기 클릭 -> 기존에 저장해둔 설정 다 저장(onSubmit) 후 해당 bugoId로 navigate
  const bugoPreviewMemberClick = async (
    index: number,
    memberBugoIFormInputs: MemberBugoIFormInput[],
  ) => {
    const bugoIdList = await getBugoIdListAfterUpdateBugo(memberBugoIFormInputs);
    const bugoId = bugoIdList && bugoIdList[index];
    if (bugoId) {
      navigate(`/bugo/${bugoIdList[index]}/preview`);
    } else {
      // console.log('error bugo preview');
    }
  };

  const headerText = useMemo(() => {
    if (memberHasPhoneNumberList.length > 0) {
      return (
        <div className="ml-2 mb-3 text-sm font-bold">
          <p>상주님께 부고를 전송합니다</p>
          <p className="text-12 font-medium">
            휴대번호가 작성된 상주님께 카톡, 문자 둘다 전송됩니다
          </p>
        </div>
      );
    } else {
      return (
        <div className="break-keep text-center text-sm font-medium">
          <p>휴대번호가 존재하는 상주님이 존재하지 않습니다</p>
          <p>
            아래 <span className="font-bold">상주정보 수정 버튼</span>을 클릭하여 주세요
          </p>
        </div>
      );
    }
  }, [memberHasPhoneNumberList.length]);

  return (
    <div>
      {headerText}
      <form>
        <MemberBugoSender
          members={memberHasPhoneNumberList}
          register={register}
          control={control}
        />
        <div className="mt-8 space-y-4">
          <Button
            type="button"
            className="button-rectangle text-white theme-bg-13"
            onClick={() => {
              //Add mode 일때
              if (useMode === FeventFormUseMode.Add) {
                setSearchParams({ feventId: fevent._id, step: '0' });
              }
              // useMode Edit 또는 없을경우
              else {
                navigate(`/fevent/${fevent._id}/edit/?step=0`);
              }
            }}
          >
            부고장 수정
          </Button>

          <Button
            onClick={handleSubmit(onSubmit)}
            className="button-rectangle text-white theme-bg-main disabled:theme-text-3 disabled:theme-bg-10"
            disabled={memberHasPhoneNumberList?.length === 0 || sendingBugo}
          >
            {useMode === FeventFormUseMode.Add
              ? '상주님께 부고전송'
              : '수정·추가된 부고전송'}
          </Button>
          <div className="grid grid-cols-2 gap-2">
            <Button
              type="button"
              className="button-rectangle text-white theme-bg-12"
              onClick={() => {
                setPreviewModalOpen(true);
              }}
            >
              부고장 미리보기
            </Button>
            <div>
              <Link to="/">
                <Button className="button-rectangle text-white theme-bg-12">
                  완료(홈으로)
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </form>

      <BottomPopup openId={previewOpenId}>
        <div className="w-screen rounded-t-2xl p-6 theme-text-1 theme-bg-1">
          {/* Title */}
          <h4 className="text-center text-sm font-bold">상주님 선택</h4>
          {/* Item List */}
          <ul className="max-h-64 overflow-y-auto pt-3 pb-4">
            {memberHasPhoneNumberList?.map((member, index) => (
              <li
                key={`select-${member?._id}`}
                onClick={(e) => {
                  handleSubmit((data) => {
                    const inputs = data.memberBugo;
                    bugoPreviewMemberClick(index, inputs);
                  })(e);
                }}
                className={`cursor-pointer border-b py-3 text-center leading-5 theme-border-1`}
              >
                {member?.fullName}
              </li>
            ))}
          </ul>
        </div>
      </BottomPopup>
      <LoadingModal open={sendingBugo} title="부고 전송중"></LoadingModal>
    </div>
  );
};

export default FeventSendBugoForm;
