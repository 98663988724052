import { Fragment } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { useAuth0 } from '@auth0/auth0-react';
import {
  auth0AccessTokenAtom,
  tokenSelector,
  userProfileSelector,
} from '@shared/state/atom/auth.atom';
import { useAsync } from 'react-use';

import { useAuth } from './useAuth';

const TokenProvider: React.FC<any> = ({ children }) => {
  const { isAuthenticated, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const { getMe } = useAuth();
  //* localStorage 저장하고 이놈이 있는가에 따라서 authenticated로 판단할 수 있다.
  const [token, setToken] = useRecoilState(tokenSelector);
  const setAuth0AccessToken = useSetRecoilState(auth0AccessTokenAtom);

  //* userProfile 저장하고 이놈으로 role 등을 다 판단
  const setUserProfile = useSetRecoilState(userProfileSelector);

  useAsync(async () => {
    if (isAuthenticated) {
      const idToken = await getIdTokenClaims();
      if (idToken?.__raw) {
        setToken(idToken.__raw);
      }
    }
  }, [isAuthenticated, getIdTokenClaims]);

  useAsync(async () => {
    if (isAuthenticated) {
      const auth0AccessToken = await getAccessTokenSilently();
      if (auth0AccessToken) {
        setAuth0AccessToken(auth0AccessToken);
      }
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useAsync(async () => {
    if (token) {
      const userProfile = await getMe(token);
      if (userProfile) {
        setUserProfile(userProfile);
      } else {
        // console.log('redirect 해야함');
      }
    }
  }, [token]);

  return <Fragment>{children}</Fragment>;
};

export { TokenProvider };
